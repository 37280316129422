import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import en from "./translation/English";
import hi from "./translation/Hindi";
import mr from "./translation/Marathi";

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: en,
        hi: hi,
        mr: mr,
    },
});

export default i18n;
