import moment from "moment";
import React from "react";
import { IoIosClose } from "react-icons/io";
import { filterByTagType } from "../constants/constant";
import "../assets/css/VisitPrescriptionSm.css";
import { useTranslation } from "react-i18next";
const VisitPrescriptionSm = ({
    setShowModal,
    consultationData,
    tagData,
    handlePrint,
}) => {
    const { t } = useTranslation();
    const getTagId = (label) => {
        const setting = tagData?.find(
            (item) => item?.name?.toLowerCase() === label?.toLowerCase()
        );
        return setting ? setting?.id : null;
    };

    return (
        <div className="cu-visit-pre-cont fixed top-0 left-0 w-full h-[100dvh] bg-white z-50">
            <div className="cu-visit-header px-4 h-[63px] w-full flex justify-between items-center text-lg text-[#454545] font-bold">
                <span>{t("prescription")}</span>
                <IoIosClose size={30} onClick={() => setShowModal(false)} />
            </div>
            <div className="cu-visit-body w-full">
                <div className="h-[69px] px-4">
                    <div className="w-full flex justify-between text-sm font-bold text-[#4D4D4D]">
                        <span>
                            {consultationData?.personal_details?.full_name}
                        </span>
                        <span>
                            {moment(consultationData?.date_created).format(
                                "MMMM DD, YYYY"
                            )}
                        </span>
                    </div>
                    <div className="text-[#000000B2] text-xs mt-1">
                        <span>{consultationData?.personal_details?.age}, </span>
                        <span className="">
                            {consultationData?.personal_details?.gender}
                        </span>{" "}
                        <br />
                        <span>
                            {consultationData?.personal_details?.phone}
                        </span>{" "}
                    </div>
                </div>
                <div className="w-full overflow-scroll">
                    {filterByTagType(
                        consultationData?.qp_cm_consultation_tags,
                        getTagId("complaints")
                    )?.length > 0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("complaints")}
                            </div>
                            <div className="mt-1">
                                {" "}
                                {filterByTagType(
                                    consultationData?.qp_cm_consultation_tags,
                                    getTagId("complaints")
                                )?.map((el, i) => {
                                    return <span key={i}>{el?.name},</span>;
                                })}
                            </div>
                        </div>
                    )}
                    {consultationData?.personal_details?.allergy?.length >
                        0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("allergies")}
                            </div>
                            <div className="mt-1">
                                {" "}
                                {consultationData?.personal_details?.allergy?.map(
                                    (el, i) => {
                                        return (
                                            <span key={i}>{el?.name}, </span>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}

                    {consultationData?.personal_details?.medical_history
                        ?.length > 0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("patient_history")}
                            </div>
                            <div className="mt-1">
                                {consultationData?.personal_details?.medical_history?.map(
                                    (el, i) => {
                                        return <span key={i}>{el.name}, </span>;
                                    }
                                )}
                            </div>
                        </div>
                    )}
                    {consultationData?.qp_cm_vitals?.length > 0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("vitals")}
                            </div>
                            <div className="mt-1">
                                {consultationData?.qp_cm_vitals?.map(
                                    (el, i) => {
                                        return (
                                            <div className="flex min-h-[34px] items-center mt-1 border">
                                                <div className="text-[#6D6D6D] text-xs w-28 pl-2">
                                                    {el?.type?.label}
                                                </div>
                                                <div className="flex-1 text-[11.27px]">
                                                    {el?.type?.label === "BP" &&
                                                    el?.reading?.length > 0 ? (
                                                        <>
                                                            {el?.reading?.map(
                                                                (item, i) => {
                                                                    return (
                                                                        <div
                                                                            className="flex gap-4 mb-1"
                                                                            key={
                                                                                i
                                                                            }
                                                                        >
                                                                            {item.timeStamp && (
                                                                                <span>
                                                                                    {moment(
                                                                                        item.timeStamp
                                                                                    ).format(
                                                                                        "hh:mm A"
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                            <span>
                                                                                {
                                                                                    item?.value1
                                                                                }

                                                                                /
                                                                                {
                                                                                    item?.value2
                                                                                }{" "}
                                                                                {
                                                                                    item?.valueUnit
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <span>
                                                            {el?.value1}{" "}
                                                            {el?.type?.unit}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}

                    {filterByTagType(
                        consultationData?.qp_cm_consultation_tags,
                        getTagId("DIAGNOSIS")
                    )?.length > 0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("diagnosis")}
                            </div>
                            <div className="mt-1">
                                {" "}
                                {filterByTagType(
                                    consultationData?.qp_cm_consultation_tags,
                                    getTagId("DIAGNOSIS")
                                )?.map((el, i) => {
                                    return <span key={i}>{el?.name},</span>;
                                })}
                            </div>
                        </div>
                    )}
                    {consultationData?.medicine_data?.length > 0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("medicine_details")}
                            </div>
                            <div className="mt-1">
                                <div>
                                    <div className="flex items-center h-[34px] p-1">
                                        <div className="w-32 ">{t("name")}</div>
                                        <div className="flex-1 px-2">
                                            {t("instructions")}
                                        </div>
                                    </div>
                                    {consultationData?.medicine_data?.map(
                                        (item, i) => {
                                            return (
                                                <div
                                                    className="flex border  p-1 text-[10px] text-[#000000] leading-[11.5px]"
                                                    key={i}
                                                >
                                                    <div className="w-32 pr-2">
                                                        {item?.medicineName}
                                                    </div>
                                                    <div className="px-2">
                                                        <div>
                                                            <span>
                                                                {item?.duration}
                                                                ,
                                                            </span>{" "}
                                                            <br></br>
                                                            <span>
                                                                {
                                                                    item?.when
                                                                        .name
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="mt-1">
                                                            <span className="text-[#6D6D6D]">
                                                                Dosage-
                                                            </span>
                                                            {item?.dosage.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {consultationData?.qp_cm_advices?.description && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("advice_and_direction")}
                            </div>
                            <div className="mt-1">
                                {consultationData?.qp_cm_advices?.description}
                            </div>
                        </div>
                    )}

                    {filterByTagType(
                        consultationData?.qp_cm_consultation_tags,
                        getTagId("TEST & INVESTIGATION")
                    )?.length > 0 && (
                        <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                            <div className="text-[#6D6D6D] font-bold">
                                {t("test_and_investigation")}
                            </div>
                            <div className="mt-1">
                                {" "}
                                {filterByTagType(
                                    consultationData?.qp_cm_consultation_tags,
                                    getTagId("TEST & INVESTIGATION")
                                )?.map((el, i) => {
                                    return <span key={i}>{el?.name},</span>;
                                })}
                            </div>
                        </div>
                    )}
                    <div className="py-2 text-xs leading-[13.8px] px-4 border-t">
                        <div className="text-[#6D6D6D] font-bold">
                            {t("next_visit_date")}
                        </div>
                        <div className="mt-1">
                            {" "}
                            <span>
                                {!consultationData?.next_visit_date ? (
                                    "-"
                                ) : (
                                    <>
                                        {moment(
                                            consultationData?.next_visit_date
                                        ).format("MMMM DD, YYYY")}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <button
                        onClick={handlePrint}
                        className="h-[40px] border rounded-md bg-[#C7EAC9] text-lg w-full"
                    >
                        Download PDF
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VisitPrescriptionSm;
