const en = {
    translation: {
        logout: "Log Out",
        home: "Home",
        appointment: "Appointments",
        chat: "Chat",
        profile: "Profile",
        medicalRecord: "Medical Record",
        bookNow: "Book Now",
        upcoming: "Upcoming",
        completed: "Completed",
        callTheClinic: "Call the clinic",
        bookAppoint: "Book Appointment",
        youHaveNo: "You have no",
        bookAppText: "Book an appointment it will show up here.",
        book: "Book",
        noSlots: "No slots available, please try another date.",
        docUnavailable: "The doctor is unavailable for this day.",
        getAdvice: " Get Expert Advice From Specialist Doctors",
        searchClinic: " Search Clinic",
        selectClinic: "Select Clinic",
        selectDoctor: "Select Doctor",
        searchDoctor: " Search Doctor",
        selectDate: "Select Date",
        next: "Next",
        Selectslot: "Select Slot",
        selectPatient: "Select Patient",
        me: "Me",
        add: "Add",
        phoneNumber: "Phone Number",
        name: "Name",
        gender: "Gender",
        male: "Male",
        female: "Female",
        other: "Other",
        addFamilyMember: "Add Family Member",
        addFamily: "Add Family",
        uploadRecords: "Upload Records",
        uploadReport: "Upload Report",
        showRecords: "Show Records",
        records: " Records",
        bookAgain: "Book Again",
        update: "Update",
        submit: "Submit",
        cancel: "Cancel",
        back: "Back",
        uploadNew: "Upload New",
        delete: "Delete",
        uploadFiles: " Upload Files",
        edit_profile: "Edit Profile",
        manage_family_profile: "Manage Family Profile",
        manage_password: "Manage Password",
        notification_settings: "Notification Settings",
        language: "Language",
        about_us: "About Us",
        link_user: "Link User",
        prescriptions: "Prescriptions",
        prescription: "Prescription",
        certificates: "Certificates",
        bills: "Bills",
        labTests: "Lab Tests",
        time: "Time",
        age: "Age",
        dob: "DOB",
        bloodGroup: "Blood Group",
        street_locality: "Street/Locality",
        pincode: "Pincode",
        city: "City",
        state: "State",
        country: "Country",
        alternateContactNumber: "Alternate Contact Number",
        report_type: "Report Type",
        signup: " Sign up",
        signIn: "Sign In",
        signUp: "Sign Up",
        linkToResetYouPassword: "link to reset your password,",
        alreadyhaveanaccount: " Already have an account?",
        forgotPassword: "Forgot Password ?",
        comingsoon: "Coming soon.",
        stayConnected: "Stay Connected!",
        currentPassword: "Current Password",
        newPassword: "New Password",
        confirmNewPassword: " Confirm New Password",
        loginViaPassword: " Login Via Password",
        loginViaOTP: " Login Via OTP",
        dontHaveAcc: " Don't have an account?  ",
        dearLoginText:
            "Dear User, Your mobile number serves as your User ID. If you haven't logged in for 2 month or longer, please click on the ",
        switchToYourFamilyMembers: " Switch to your family members",
        viewDetails: " View Details",
        or: "OR",
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        Sun: "Sun",
        Mon: "Mon",
        Tue: "Tue",
        Wed: "Wed",
        Thu: "Thu",
        Fri: "Fri",
        Sat: "Sat",
        morning: "Morning",
        evening: "Evening",
        showless: "Show less",
        showmore: "Show more",
        available: "available",
        slots: "slots",
        consultationFees: " Consultation fees :",
        visits: "Visits",
        selectReportType: "Select Report Type",
        reportName: "Report Name",
        enterReportName: "Enter Report Name",
        note: "  Note: This field is editable. You can change the report name.",
        uploadFrom: "Upload From",
        camera: "Camera",
        images: "Images",
        files: "Files",
        deleteProfile: "Delete Profile",
        email: "Email",
        enter: "Enter",
        select: "Select",
        reportDate: "Report Date",
        enterReportDate: "Enter Report Date",
        description: "Description",
        enterdescription: "Enter Description",
        enterOTP: "Enter OTP",
        enterName: "Enter Name",
        enterTime: "Enter Time",
        enteryourPhoneNumber: "Enter your Phone Number",
        enteryourEmail: "Enter your Email",
        enteryourName: "Enter your Name",
        enter4digitotpsentto: "Enter 4 digit otp sent to",
        mobileNumber: "Mobile Number",
        getOTP: "Get OTP",
        enterNewPassword: "Enter New Password",
        confirmPassword: "Confirm Password",
        complaints: "Complaints",
        allergies: "Allergies",
        patient_history: "Patient History",
        vitals: "Vitals",
        diagnosis: "Diagnosis",
        medicine_details: "Medicine Details",
        name: "Name",
        instructions: "Instructions",
        dosage: "Dosage",
        advice_and_direction: "Advice and Direction",
        test_and_investigation: "Test and Investigation",
        next_visit_date: "Next Visit Date",
        firstName: "First Name",
        lastName: "Last Name",
        password: "Password",
        andhraPradesh: "Andhra Pradesh",
        arunachalPradesh: "Arunachal Pradesh",
        assam: "Assam",
        bihar: "Bihar",
        chhattisgarh: "Chhattisgarh",
        goa: "Goa",
        gujarat: "Gujarat",
        haryana: "Haryana",
        himachalPradesh: "Himachal Pradesh",
        jharkhand: "Jharkhand",
        karnataka: "Karnataka",
        kerala: "Kerala",
        madhyaPradesh: "Madhya Pradesh",
        maharashtra: "Maharashtra",
        manipur: "Manipur",
        meghalaya: "Meghalaya",
        mizoram: "Mizoram",
        nagaland: "Nagaland",
        odisha: "Odisha",
        punjab: "Punjab",
        rajasthan: "Rajasthan",
        sikkim: "Sikkim",
        tamilNadu: "Tamil Nadu",
        telangana: "Telangana",
        tripura: "Tripura",
        uttarPradesh: "Uttar Pradesh",
        uttarakhand: "Uttarakhand",
        westBengal: "West Bengal",
        india: "India",
        disclaimerPoints: "Disclaimer Points",
        readAllThePoints: "I have read all the points",
        accept: "Accept",
    },
};

export default en;
