import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "jotai";
import { QueryClientProvider } from "react-query";
import { QueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Routing from "./Routing";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            retry: false,
            staleTime: 1000 * 60 * 60 * 24 * 365,
        },
    },
});

function App() {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const handleOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener("online", handleOnlineStatus);
        window.addEventListener("offline", handleOnlineStatus);

        // Cleanup event listeners
        return () => {
            window.removeEventListener("online", handleOnlineStatus);
            window.removeEventListener("offline", handleOnlineStatus);
        };
    }, []);

    if (!isOnline || !navigator.onLine) {
        return <div>You are offline</div>;
    }

    return (
        <Provider>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <Routing />
                    <ToastContainer />
                </QueryClientProvider>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
