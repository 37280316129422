import { atom } from "jotai";

export const clinicAtom = atom([]);

export const selectedClinicAtom = atom({ id: null, name: "", logo: "" });

export const doctorsAtom = atom([]);

export const selectedDoctorAtom = atom({});

//This reportNameAtom is used in UploadRelatedRecord file
export const reportNameAtom = atom("");

export const changedLanguageAtom = atom(
    localStorage.getItem("selectedLanguage") || navigator.language
);

export const userDataAtom = atom([]);

export const userIdAtom = atom("");

export const selectedUserAtom = atom(null);

export const slotDataAtom = atom([]);

export const selectedD = atom({});

export const availability_data_atom = atom(null);

export const slot_status = atom("upcoming");

export const authTokenAtom = atom(localStorage.getItem("access_token") || null);
