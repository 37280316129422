import React, { useState, useEffect, useRef } from "react";
import { getImageURL } from "../apis/image";
import { IoMdClose } from "react-icons/io";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const ImagePreview = ({
    uploadedFile = [],
    mainImage,
    setMainImage,
    setShowPreview,
    setSelectedIndex,
    selectedIndex,
}) => {
    // const [selectedIndex, setSelectedIndex] = useState(0);
    const [zoomed, setZoomed] = useState(false);
    const [initialTouchPosition, setInitialTouchPosition] = useState(null);
    const mainImageRef = useRef(null);
    useEffect(() => {
        setMainImage(uploadedFile[selectedIndex]?.url);
    }, [selectedIndex, uploadedFile, setMainImage]);

    const handlePrevious = () => {
        setSelectedIndex((prevIndex) =>
            prevIndex === 0 ? uploadedFile.length - 1 : prevIndex - 1
        );
        setZoomed(false);
    };

    const handleNext = () => {
        setSelectedIndex((prevIndex) =>
            prevIndex === uploadedFile.length - 1 ? 0 : prevIndex + 1
        );
        setZoomed(false);
    };

    const handleThumbnailClick = (index) => {
        setSelectedIndex(index);
        setZoomed(false); // Reset zoom when changing images
    };

    const handleDoubleClick = () => {
        setZoomed((prevZoom) => !prevZoom);
    };

    const handleTouchStart = (event) => {
        setInitialTouchPosition(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
        if (initialTouchPosition === null) {
            return;
        }

        const currentPosition = event.touches[0].clientX;
        const diff = currentPosition - initialTouchPosition;

        if (Math.abs(diff) > 50) {
            // You can adjust this threshold for swipe detection
            if (diff < 0) {
                handleNext(); // Swipe left
            } else {
                handlePrevious(); // Swipe right
            }
            setInitialTouchPosition(null);
        }
    };

    return (
        <div className="fixed inset-0 z-50 w-screen h-screen top-0 right-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="relative rounded-lg md:rounded-2xl p-5 w-[95%] md:w-[85%] h-[90%] flex items-center flex-col gap-2 bg-white">
                <IoMdClose
                    size={28}
                    className="absolute -top-8 md:-top-5 md:-right-10 right-0 cursor-pointer text-white"
                    onClick={() => setShowPreview(false)}
                />
                <GrFormPrevious
                    size={35}
                    className="absolute md:top-[50%] top-[40%] -left-3 md:-left-8 cursor-pointer text-gray-500 md:text-white"
                    onClick={handlePrevious}
                />
                <GrFormNext
                    size={35}
                    className="absolute md:top-[50%] top-[40%] -right-3 md:-right-8 cursor-pointer text-gray-500 md:text-white"
                    onClick={handleNext}
                />
                <div className="flex-1 w-full h-full overflow-auto flex justify-center">
                    <img
                        ref={mainImageRef}
                        src={getImageURL(mainImage)}
                        alt="Main"
                        className={`h-[95%] object-contain rounded-lg shadow-md transition-transform duration-300 ease-in-out ${
                            zoomed ? "scale-150" : "scale-100"
                        } ${zoomed ? "cursor-zoom-out" : "cursor-zoom-in"}`}
                        onDoubleClick={handleDoubleClick}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                    />
                </div>
                <div className="w-full flex justify-center mt-4 overflow-auto">
                    <div className="h-full flex space-x-4">
                        {uploadedFile.map((file, index) => (
                            <img
                                key={index}
                                className={`thumbnail w-24 h-24 object-cover border rounded-lg cursor-pointer hover:opacity-75 ${
                                    index === selectedIndex
                                        ? "border-blue-500"
                                        : ""
                                }`}
                                src={getImageURL(file.url)}
                                alt={file.filename}
                                onClick={() => handleThumbnailClick(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagePreview;
