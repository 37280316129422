import React, { useEffect, useState } from "react";
import { cufrontIcons } from "../assets/icons/icons";
import ImagePreview from "./ImagePreview";

const truncateFileName = (fileName, limit = 20) => {
    if (fileName.length > limit) {
        return `${fileName.substring(0, limit)}...`;
    }
    return fileName;
};

const MedicalRecordCard = ({
    item,
    onClick,
    date,
    handleOpenPdfViewer,
    handleDelete,
}) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    // const [openImgViewer, setOpenImgViewer] = useState(true);

    // console.log(item, "");

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 640);
    };

    const handleOpenImgViewer = () => {};

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getFileName = () => {
        if (!item.file_name) {
            return "Prescription";
        }
        return isSmallScreen
            ? truncateFileName(item.file_name)
            : item.file_name;
    };

    return (
        <>
            {/* {
                <ImagePreview
                    setShowPreview={true}
                    setMainImage={() => {}}
                    mainImage={"testing/patients/300/1718878348_dr.jpg"}
                />
            } */}

            <div className="border-[0.5px] border-[#00000033] h-[116px] rounded-[5px] flex items-center gap-2 px-4 py-3">
                <div className="w-[80px] h-[95px] ">
                    <img
                        className="h-full w-full"
                        src={cufrontIcons?.documentIcon}
                        alt=".."
                    />
                </div>
                <div
                    className="flex-1 h-full cursor-pointer flex items-center"
                    onClick={!item.file_name ? onClick : handleOpenPdfViewer}
                >
                    <div className="h-fit">
                        <p
                            className="text-[#575CD0] text-[16px] leading-[18.4px] mt-2 ml-4 text-ellipsis overflow-hidden"
                            onClick={handleOpenImgViewer}
                        >
                            {getFileName()}
                        </p>

                        <p className="text-[14px] text-[#5B5B5B] leading-[16.1px] mt-3 ml-4 text-ellipsis overflow-hidden">
                            {item.file_name
                                ? item.file_description
                                : `Dr. ${item?.doctor_user?.first_name} ${item?.doctor_user?.last_name}`}
                        </p>
                    </div>
                </div>
                <div className=" flex h-full flex-col justify-between text-end ml-5">
                    <div className="text-[14px] text-[#777777] leading-[16.1px] mt-3">
                        {date}
                    </div>
                    {item.files_id && (
                        <div className="flex justify-end gap-2 cursor-pointer">
                            <img
                                className="w-[20px] h-[20px]"
                                src={cufrontIcons.delete}
                                alt="..."
                                onClick={handleDelete}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default MedicalRecordCard;
