import "../css/InvoicePDF.css";
import { useReactToPrint } from "react-to-print";
import { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import { getImageURL } from "../../apis/image";
import { useItemsFilter } from "../../apis/queries";

const InvoicePDF = ({ closePreview, uuid, type }) => {
    const [invoiceData, setInvoiceData] = useState(null);
    const billPrintRef = useRef(null);
    const billType = invoiceData?.bill_type;
    const patienName = invoiceData?.patient_details?.full_name;
    const billNo = invoiceData?.invoice_no;
    const bill_type = {
        IPD: invoiceData?.admission_details?.IPD_No,
        OPD: invoiceData?.opd?.opd_number,
    };
    const DOA = `${moment(
        invoiceData?.admission_details?.admission_date
    ).format(
        "DD-MMM-YYYY"
    )} at ${invoiceData?.admission_details?.admission_time}`;
    const DOD = `${moment(
        invoiceData?.admission_details?.discharge_date
    ).format(
        "DD-MMM-YYYY"
    )} at ${invoiceData?.admission_details?.discharge_time}`;
    const opdTime = invoiceData?.opd?.date;
    const amountInWords = invoiceData?.amount_in_word;
    const invoiceDate = moment(invoiceData?.invoice_date).format("DD-MMM-YYYY");
    const injury_no = invoiceData?.admission_details?.injury_no;
    const customerName =
        invoiceData?.institution_customer?.institution_name || "";
    const diagnosis = invoiceData?.admission_details?.diagnosis;
    const treatment = invoiceData?.admission_details?.treatment;

    // const { data, isLoading } = useItemsFilter(
    //     "payments/billing/",
    //     `?action=getcreatePDFInvoice&backend=True&invoice_data=${uuid}`,
    //     uuid ? true : false
    // );

    // Define the print function using the useReactToPrint hook
    const handlePrint = useReactToPrint({
        content: () => billPrintRef.current,
    });

    // useEffect(() => {
    //     if (invoiceData) {
    //         handlePrint();
    //         closePreview();
    //     }
    // }, [invoiceData]);

    const END_POINT = `clinics/shared_medical_record/`;

    const { data, isLoading } = useItemsFilter(
        END_POINT,
        `?RID=${uuid}&type=${type}`,
        uuid && type ? true : false
    );

    useEffect(() => {
        if (data?.invoiceData) {
            setInvoiceData(data?.invoiceData);
        }
    }, [data]);

    if (isLoading) {
        return <div>Please wait we are downloading your PDF...</div>;
    }

    return (
        <div className="opacity-0">
            <div
                ref={billPrintRef}
                className="flex justify-center items-center"
                style={{ height: "29.7cm" }}
            >
                <div className="cu-invoice-email-template">
                    <div className="w-full">
                        <img src={getImageURL("")} className="w-full" />
                    </div>
                    <div className="cu-invoice-billing-type">
                        {billType} Billing Summary
                    </div>
                    <div className="cu-invoice-patient-details">
                        <div>
                            {customerName && (
                                <div>{`Customer: ${customerName}`}</div>
                            )}
                            <div>{`Patient Name: Mr. ${patienName}`}</div>
                            <div>{`Bill No: ${billNo}`}</div>
                            <div>{`Invoice Date: ${invoiceDate}`}</div>
                        </div>
                        <div>
                            <div>{`${billType} No: ${bill_type[billType]}`}</div>
                            {injury_no && (
                                <div>{`Injury Slip No: ${injury_no}`}</div>
                            )}
                            {billType === "IPD" && <div>{`DOA: ${DOA}`}</div>}
                            {billType === "IPD" && <div>{`DOD: ${DOD}`}</div>}
                            {billType === "OPD" &&
                                opdTime !== "Invalid date" && (
                                    <div>{`OPD Date: ${opdTime}`}</div>
                                )}
                        </div>
                    </div>
                    <div className="cu-invoice-pdf-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Rate/Package</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceData?.invoice_lines?.map(
                                    (invcSer, pkgIdx) => {
                                        if (invcSer?.line_package) {
                                            return invcSer?.line_package?.services?.map(
                                                (pkgSer, pkgSerIdx) => {
                                                    return (
                                                        <tr
                                                            key={`${pkgSer?.clinic_services_id?.id}${pkgSerIdx}`}
                                                        >
                                                            <td>
                                                                {
                                                                    pkgSer?.name_of_service
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    borderBottom:
                                                                        "none",
                                                                }}
                                                            >
                                                                {pkgSerIdx ===
                                                                Math.ceil(
                                                                    invcSer
                                                                        ?.line_package
                                                                        ?.services
                                                                        ?.length /
                                                                        2
                                                                ) -
                                                                    1
                                                                    ? invcSer?.quantity ||
                                                                      0
                                                                    : ""}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    borderBottom:
                                                                        "none",
                                                                }}
                                                            >
                                                                {pkgSerIdx ===
                                                                Math.ceil(
                                                                    invcSer
                                                                        ?.line_package
                                                                        ?.services
                                                                        ?.length /
                                                                        2
                                                                ) -
                                                                    1
                                                                    ? invcSer
                                                                          ?.line_package
                                                                          ?.name_of_package
                                                                    : ""}
                                                            </td>
                                                            {pkgSerIdx ===
                                                                Math.ceil(
                                                                    invcSer
                                                                        ?.line_package
                                                                        ?.services
                                                                        ?.length /
                                                                        2
                                                                ) -
                                                                    1 && (
                                                                <td
                                                                    style={{
                                                                        borderBottom:
                                                                            "none",
                                                                    }}
                                                                >{`Rs ${invcSer?.total}`}</td>
                                                            )}
                                                        </tr>
                                                    );
                                                }
                                            );
                                        } else if (
                                            invcSer?.line_service?.name_of_service?.toUpperCase() ===
                                            "MEDICINE CHARGES"
                                        ) {
                                            return (
                                                <Fragment
                                                    key={`${invcSer?.line_service?.id}${pkgIdx}`}
                                                >
                                                    <tr>
                                                        <td>
                                                            <b>
                                                                {
                                                                    invcSer
                                                                        ?.line_service
                                                                        ?.name_of_service
                                                                }
                                                            </b>
                                                        </td>
                                                        <td>
                                                            <b>
                                                                {invcSer?.type ===
                                                                "Qty"
                                                                    ? invcSer?.quantity
                                                                    : "-"}
                                                            </b>
                                                        </td>
                                                        <td>
                                                            <b>
                                                                {Number(
                                                                    invcSer?.price
                                                                ) > 0
                                                                    ? invcSer?.price
                                                                    : "-"}
                                                            </b>
                                                        </td>
                                                        <td>
                                                            <b>{`-`}</b>
                                                        </td>
                                                    </tr>
                                                    {invcSer?.sub_lines?.map(
                                                        (subline, subIdx) => {
                                                            return (
                                                                <tr
                                                                    className="text-[12px]"
                                                                    key={subIdx}
                                                                >
                                                                    <td>
                                                                        {`${subline?.medicine_name} `}
                                                                        {subline?.generic_name ===
                                                                            "nan" ||
                                                                        !subline?.generic_name
                                                                            ? ""
                                                                            : `(${subline?.generic_name})`}
                                                                        {subline?.batch_no?.map(
                                                                            (
                                                                                ele
                                                                            ) => {
                                                                                if (
                                                                                    ele?.consumed_qty >
                                                                                    0
                                                                                ) {
                                                                                    return (
                                                                                        <div
                                                                                            className="text-[11.5px]"
                                                                                            key={
                                                                                                ele?.batchId
                                                                                            }
                                                                                        >{`Batch No: ${ele?.batchNo}, Qty: ${
                                                                                            ele?.consumed_qty
                                                                                        }, expiry: ${moment(
                                                                                            ele?.expiry
                                                                                        ).format(
                                                                                            "DD MMM YYYY"
                                                                                        )}`}</div>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {subline?.quantity ||
                                                                            0}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            subline?.price
                                                                        }
                                                                    </td>
                                                                    <td>{`Rs ${
                                                                        Number(
                                                                            subline?.price
                                                                        ) *
                                                                        Number(
                                                                            subline?.quantity
                                                                        )
                                                                    }`}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </Fragment>
                                            );
                                        } else {
                                            return (
                                                <tr
                                                    key={`${invcSer?.line_service?.id}${pkgIdx}`}
                                                >
                                                    <td>
                                                        {
                                                            invcSer
                                                                ?.line_service
                                                                ?.name_of_service
                                                        }
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                "1px solid #000000cc",
                                                        }}
                                                    >
                                                        {invcSer?.type === "Qty"
                                                            ? invcSer?.quantity
                                                            : "-"}
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                "1px solid #000000cc",
                                                        }}
                                                    >
                                                        {Number(
                                                            invcSer?.price
                                                        ) > 0
                                                            ? invcSer?.price
                                                            : "-"}
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                "1px solid #000000cc",
                                                        }}
                                                    >{`Rs ${invcSer?.total}`}</td>
                                                </tr>
                                            );
                                        }
                                    }
                                )}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style={{ fontWeight: "700" }}>Total</td>
                                    <td
                                        style={{ fontWeight: "700" }}
                                    >{`Rs ${invoiceData?.invoice_total}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <div className="text-[13px]">
                            <b>Total Amount in words:</b>
                            {` ${amountInWords} Only.`}
                        </div>
                        {diagnosis && (
                            <div className="text-[13px]">
                                <b>Diagnosis:</b>
                                {` ${diagnosis}`}
                            </div>
                        )}
                        {treatment && (
                            <div className="text-[13px]">
                                <b>Treatment:</b>
                                {` ${treatment}`}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoicePDF;
