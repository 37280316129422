import React from "react";
import CuButton from "./CuButton";
import { RiErrorWarningFill } from "react-icons/ri";
const Confirmation = ({ onClick, onCancel, heading, loading }) => {
    return (
        <div className="md:w-[384px] w-full m-auto h-[238px] py-2">
            <div className="m-auto  flex justify-center items-center mt-2">
                <RiErrorWarningFill color="#EC1C1C" size={25} />
            </div>
            <div></div>
            <div className="mt-3">
                <div className="text-center text-lg font-bold">{heading}</div>
                <div className="w-[217px] m-auto text-center text-sm text-[#979797] mt-3">
                    Are you sure you want to delete this file? This action
                    cannot be undone.
                </div>
            </div>
            <div className="w-full flex justify-center items-center gap-4 mt-10">
                <CuButton
                    className={`w-32 h-11 rounded-[5px] border`}
                    onClick={onCancel}
                >
                    Cancel
                </CuButton>
                <CuButton
                    className={`w-32 h-11 rounded-[5px] bg-[#FF8484]`}
                    onClick={onClick}
                    isLoading={loading}
                >
                    Delete
                </CuButton>
            </div>
        </div>
    );
};

export default Confirmation;
