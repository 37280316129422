import { useMutation } from "react-query";
import { secureAxios } from "./authSetup";
import { queryClient } from "../App";
import { ApiEndPoint } from "../constants/constant";
import { GetCSRFTokenFromCookies } from "../utils/_helpers";
import axios from "axios";

export const useCreate = (collection, extra_invalidate = []) => {
    let query = `${collection}`;

    return useMutation(
        (newItem) => {
            return secureAxios
                .post(`/${query}`, newItem)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", collection]);
                if (extra_invalidate) {
                    extra_invalidate.forEach((extra) => {
                        queryClient.invalidateQueries(["items", extra]);
                    });
                }
            },
        }
    );
};

export const useCreateItem = (
    collection,
    extra_invalidate = null,
    extra_invalidate_1
) => {
    // let query = `items/${collection}`;

    let query = `${collection}`;

    return useMutation(
        (newItem) => {
            return secureAxios
                .post(`${query}`, newItem)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries([collection]);
                if (extra_invalidate) {
                    queryClient.invalidateQueries([extra_invalidate]);
                }

                // if (extra_invalidate_1) {
                //   queryClient.invalidateQueries(["items", extra_invalidate_1]);
                // }
            },
        }
    );
};

// export const useSecureUpdateItem = (collection, extra_invalidate = null) => {
//   let query;
//   if (collection === ApiEndPoint.USERS) {
//     query = collection;
//   } else {
//     query = `items/${collection}`;
//   }

//   return useMutation(
//     (updatedItem) => {
//       return secureAxios
//         .patch(`/${query}/${updatedItem.id}`, updatedItem.data)
//         .then((res) => res.data);
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         if (extra_invalidate) {
//           queryClient.invalidateQueries(["items", extra_invalidate]);
//         }

//         queryClient.invalidateQueries(["items", collection]);
//       },
//     }
//   );
// };

// export const useSecureUpdateItem = (collection, extra_invalidate = null) => {
//   let query = `/${collection}`
//   // if (collection === ApiEndPoint.USERS) {
//   //   query = collection;
//   // } else {
//   //   query = `items/${collection}`;
//   // }

//   return useMutation(
//     (updatedItem) => {
//       return secureAxios
//         .patch(`/${query}/${updatedItem.id}`, updatedItem.data)
//         .then((res) => res.data);
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         if (extra_invalidate) {
//           queryClient.invalidateQueries(["items", extra_invalidate]);
//         }

//         queryClient.invalidateQueries(["items", collection]);
//       },
//     }
//   );
// };

export const useSecureUpdateItem = (collection, extra_invalidate = null) => {
    let query = `/${collection}`;
    return useMutation(
        (updatedItem) => {
            return secureAxios
                .patch(`${query}/`, updatedItem.data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries([collection]);

                if (extra_invalidate) {
                    queryClient.invalidateQueries([extra_invalidate]);
                }

                // queryClient.invalidateQueries(["items", collection]);
            },
        }
    );
};

export const useDeleteItem = (collection, extra_invalidate = null) => {
    // let query = `items/${collection}/`;

    let query = `${collection}`;

    return useMutation(
        (data) => {
            // return secureAxios.delete(`/${query}${id}`).then((res) => res.data);
            return secureAxios.delete(`${query}`, data).then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                if (extra_invalidate) {
                    queryClient.invalidateQueries([extra_invalidate]);
                }
                // queryClient.invalidateQueries(["items", collection]);

                queryClient.invalidateQueries([collection]);
            },
        }
    );
};
