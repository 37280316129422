import { useMutation } from "react-query";
import { BASE_URL, restoreAccessToken, secureAxios } from "./authSetup";
import { ApiEndPoint, IMAGE_BASE_URL, s3url } from "../constants/constant";
import { queryClient } from "../App";

export const useUploadProfile = (
    userId,
    setFormData,
    profile_img,
    staticClinicId
) => {
    return useMutation((formData) => {
        return secureAxios
            .post(`/users/files/`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                return secureAxios.patch(`users/`, {
                    action: "patchUserDetails",
                    frontend: "True",
                    id: userId,
                    profile_image: res?.data[0]?.url,
                });
            })
            .then((res) => {
                setFormData(res.data);
                queryClient.invalidateQueries(["users"]);
            })
            .then((res) => res);
    });
};

export const useUploadfile = (payload) => {
    return useMutation((formData) => {
        return secureAxios
            .post(`users/files/`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                return secureAxios.post(`users/`, {
                    action: "postUserRecords",
                    // clinic_id: 1,
                    frontend: "True",
                    files_id: res?.data[0]?.url,
                    files: [res.data],
                    ...payload,
                });
            })
            .then((res) => {
                queryClient.invalidateQueries(["users/"]);
                return res.data;
            });
    });
};

export const useUpload = () => {
    return useMutation((formData) => {
        return secureAxios
            .post(`users/files/`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                queryClient.invalidateQueries(["users/"]);
                return res.data;
            });
    });
};
export const getImageURL = (path) => {
    if (IMAGE_BASE_URL) {
        return `${IMAGE_BASE_URL}/${path}`;
    }
};
