// MobileModal.js
import React, { useState, useEffect, useRef } from "react";
import "../assets/css/CuMobileModal.css";

function CuMobileModal({
    isOpen,
    closeModal,
    modalClassName = "modal-container",
    event,
    children,
}) {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
                document.body.classList.remove("modal-open");
            }
        };

        if (isOpen) {
            document.body.classList.add("modal-open");

            document.addEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.body.classList.remove("modal-open");

            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <div>
            {isOpen && (
                <>
                    <div className="modal-overlay" onClick={closeModal}></div>
                    <div className={modalClassName}>
                        <div
                            className={
                                modalClassName === "modal-container-top-right"
                                    ? "modal-container-top-right-modal-content"
                                    : modalClassName === "laptop-modal"
                                      ? "laptop-modal-content"
                                      : "modal-content"
                            }
                            ref={modalRef}
                        >
                            {children}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default CuMobileModal;
