import { useRef, useState } from "react";
import { getImageURL } from "../../apis/image";
import "../css/PrescriptionPDF.css";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { modifyString } from "../../utils/_helpers";
import { ApiEndPoint, pageSizes } from "../../constants/constant";
import { useItemsFilter } from "../../apis/queries";
import { useMediaQuery } from "react-responsive";
import CuModal from "../../layouts/CuModal";
import VisitPrescription from "../../layouts/VisitPrescription";
import VisitPrescriptionSm from "../../layouts/VisitPrescriptionSm";

const PrescriptionPDF = ({ closePreview, uuid, type }) => {
    const printContentRef = useRef(null);
    const sm = !useMediaQuery({ query: "(min-width:768px)" });
    const [showModal, setShowModal] = useState(true);

    // Define the print function using the useReactToPrint hook
    const handlePrint = useReactToPrint({
        content: () => printContentRef.current,
    });

    // useEffect(() => {
    //     handlePrint();
    //     closePreview();
    // }, []);

    // const { data: tagData, isLoading: tagLoading } = useItemsFilter(
    //     ApiEndPoint.MEDICINE_TAG_DATA,
    //     `?action=getTagTypes&clinic_id=1&backend=True`
    // );

    const END_POINT = `clinics/shared_medical_record/`;

    const { data: prescriptionData, isLoading: prescriptionLoading } =
        useItemsFilter(
            END_POINT,
            `?RID=${uuid}&type=${type}`,
            uuid && type ? true : false
        );

    let data = {};
    let userDetails = [];
    let allergies = [];
    let patientHistory = [];
    let settings = {};
    let header_footer_setup = {};
    let tagData = [];

    if (prescriptionData?.length > 0) {
        data = prescriptionData[0]?.content?.data;
        userDetails = prescriptionData[0]?.content?.userDetails;
        allergies = prescriptionData[0]?.content?.allergies;
        patientHistory = prescriptionData[0]?.content?.patientHistory;
        settings = prescriptionData[0]?.content?.settings;
        header_footer_setup = prescriptionData[0]?.content?.header_footer_setup;
        tagData = prescriptionData[0]?.content?.tagData?.data || [];
    }

    const getVitalToRender = (vitalData) => {
        let vital = "";
        const count = vitalData?.type?.no_of_fields;
        const seperator = vitalData?.type?.seperator;
        let result = [];

        for (let i = 1; i <= count; i++) {
            vital += `${vitalData[`value${i}`]} ${i === count ? "" : seperator} `;
        }

        result.push(
            <span
                key={vitalData?.id}
            >{`${vital} ${vitalData?.selected_unit}`}</span>
        );

        if (vitalData?.type?.label?.toLowerCase() === "bp") {
            const readings =
                vitalData?.reading?.map((ele, idx) => (
                    <span key={`${idx}-${ele?.value1}-${ele?.value2}`}>{`${
                        ele?.value1
                    } / ${ele?.value2} ${ele?.valueUnit} (${moment(
                        ele?.timeStamp
                    ).format("DD MMMM YYYY hh:mm A")})`}</span>
                )) || [];
            result = [...result, ...readings];
        }

        return result;
    };

    const filterByTagType = (data, tagType) => {
        return data?.filter((item) => item?.tag_type?.includes(tagType));
    };

    const renderSection = (sectionTitle, consultationData, tagId) => {
        const filteredTags =
            filterByTagType(consultationData?.qp_cm_consultation_tags, tagId) ||
            [];

        const matchText = modifyString(sectionTitle, true);

        switch (matchText) {
            case "COMPLAINTS":
                if (filteredTags?.length > 0) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[14%]">
                                {sectionTitle}
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {filteredTags?.map((ele, ind) => (
                                    <div
                                        key={`${ele?.id}-${ind}`}
                                        className={`text-gray-600 font-light`}
                                    >
                                        {`${ind === 0 ? "-" : ""}${ele?.name}${
                                            ele?.remark
                                                ? ` (since ${ele?.remark})`
                                                : ""
                                        }${ind === filteredTags?.length - 1 ? "" : ","}`}
                                    </div>
                                ))}
                            </div>
                        </section>
                    );
                }
                break;

            case "VITALS":
                if (consultationData?.qp_cm_vitals[0]?.value1) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[14%]">
                                {sectionTitle}
                            </div>
                            <div className="flex flex-wrap gap-10">
                                {consultationData?.qp_cm_vitals?.map(
                                    (ele, ind) => (
                                        <div
                                            className="flex flex-col gap-3"
                                            key={`${ele?.id}-${ind}`}
                                        >
                                            <span className="cu-print-preview-vital-label">
                                                {ele?.type?.label}
                                            </span>
                                            <span className="cu-print-preview-vital-value">
                                                {getVitalToRender(ele)?.map(
                                                    (vtl) => vtl
                                                )}
                                            </span>
                                        </div>
                                    )
                                )}
                            </div>
                        </section>
                    );
                }

                break;
            case "ALLERGY":
                if (allergies && allergies?.length > 0) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className={`text-gray-600 font-light w-[14%]`}>
                                Allergies
                            </div>
                            <div className="flex gap-2 items-center">
                                {allergies?.map((ele, ind) => (
                                    <div
                                        key={`${ele?.id}-${ind}`}
                                        className={`text-gray-600 font-light`}
                                    >
                                        {`${ind === 0 ? "-" : ""}${ele?.name}${
                                            ind === allergies?.length - 1
                                                ? ""
                                                : ","
                                        }`}
                                    </div>
                                ))}
                            </div>
                        </section>
                    );
                }

                break;

            case "PATIENTHISTORY":
                if (patientHistory && patientHistory?.length > 0) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[14%]">
                                Patient history
                            </div>

                            <div className="flex gap-2 items-center">
                                {patientHistory?.map((ele, ind) => (
                                    <div
                                        key={`${ele?.id}-${ind}`}
                                        className={`text-gray-600 `}
                                    >
                                        {`${ind === 0 ? "-" : ""}${ele?.name}${
                                            ind === patientHistory?.length - 1
                                                ? ""
                                                : ","
                                        }`}
                                    </div>
                                ))}
                            </div>
                        </section>
                    );
                }
                break;

            case "DIAGNOSIS":
                if (filteredTags?.length > 0) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[14%]">
                                Diagnosis
                            </div>
                            <div className="flex gap-2 items-center">
                                {filteredTags?.map((ele, ind) => (
                                    <div
                                        key={`${ele?.id}-${ind}`}
                                        className={`text-gray-600 font-light`}
                                    >
                                        {`${ind === 0 ? "-" : ""}${ele?.name}${
                                            ind === filteredTags?.length - 1
                                                ? ""
                                                : ","
                                        }`}
                                    </div>
                                ))}
                            </div>
                        </section>
                    );
                }
                break;
            case "MEDICINEDETAILS":
                if (
                    consultationData?.medicine_data &&
                    consultationData?.medicine_data?.length > 0
                ) {
                    return (
                        <div className="overflow-x-auto border-b pt-4">
                            <div>
                                <span className="font-size: 24px;">R</span>
                                <span className="font-size: 12px;">x</span>
                            </div>
                            <table className="min-w-full table-auto text-sm text-left">
                                <thead className="text-xs text-gray-600 border uppercase bg-white">
                                    <tr>
                                        <th scope="col" className="px-6 py-1">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-1">
                                            Medicine Name
                                        </th>
                                        <th scope="col" className="px-6 py-1">
                                            Dose
                                        </th>
                                        <th scope="col" className="px-6 py-1">
                                            Duration
                                        </th>
                                        <th scope="col" className="px-6 py-1">
                                            Remark
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {consultationData?.medicine_data?.map(
                                        (ele, ind) => (
                                            <>
                                                <tr
                                                    key={`${ele?.id}-${ind}`}
                                                    className="bg-white border-b"
                                                >
                                                    <td className="px-6 py-0">
                                                        {ind + 1}
                                                    </td>
                                                    <td className="px-6 py-0">
                                                        {ele?.medicineName}
                                                        <br></br>
                                                        <span className="font-light">
                                                            {
                                                                ele?.genericName
                                                                    ?.name
                                                            }
                                                        </span>{" "}
                                                    </td>
                                                    <td className="px-6 py-0">
                                                        {ele?.dosage?.name}
                                                        <br></br>
                                                        <span className="font-light">
                                                            {ele?.when?.name}
                                                        </span>{" "}
                                                    </td>
                                                    <td className="px-6 py-0">
                                                        {ele?.duration}
                                                    </td>
                                                    <td className="px-6 py-0">
                                                        {ele?.notes?.name}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    );
                }

                break;

            case "ADVICE&DIRECTION":
                if (consultationData?.qp_cm_advices?.description) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[16%]">
                                Advice & directions
                            </div>
                            <div className="text-gray-600 font-light">
                                -{consultationData?.qp_cm_advices?.description}
                            </div>
                        </section>
                    );
                }

                break;
            case "TEST&INVESTIGATION":
                if (filteredTags?.length > 0) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[16%]">
                                Test & Investigation
                            </div>
                            <div className="flex gap-2 items-center">
                                {filteredTags?.map((ele, ind) => (
                                    <div
                                        key={`${ele?.id}-${ind}`}
                                        className="text-gray-600 font-light"
                                    >
                                        {`${ind === 0 ? "-" : ""}${ele?.name}${
                                            ind === filteredTags?.length - 1
                                                ? ""
                                                : ","
                                        }`}
                                    </div>
                                ))}
                            </div>
                        </section>
                    );
                }

                break;

            case "OBSERVATION":
                if (data?.observation) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[16%]">
                                Observation:-
                            </div>
                            <div className="text-gray-600 font-light">
                                {data?.observation}
                            </div>
                        </section>
                    );
                }
                break;

            case "NEXTVISIT":
                if (data?.next_visit_date) {
                    return (
                        <section className="flex items-center border-b pt-4">
                            <div className="text-gray-600 font-light w-[16%]">
                                Next visit Date:-
                            </div>
                            <div className="text-gray-600 font-light">
                                {moment(
                                    data?.next_visit_date,
                                    "YYYY-MM-DD"
                                ).format("MMMM D, YYYY")}
                            </div>
                        </section>
                    );
                }
                break;

            default:
                return null;
        }
    };

    const bodyHeightInCm =
        Number(pageSizes[settings?.pageSize]?.height) -
        (Number(header_footer_setup[settings?.pageSize]?.headerHeight) +
            Number(header_footer_setup[settings?.pageSize]?.footerHeight) +
            Number(settings?.signatureHeight));

    const consultationData = {
        ...data,
        personal_details: userDetails?.length > 0 ? userDetails[0] : {},
    };

    if (prescriptionLoading) {
        return <div>Please Wait we are fetching your prescription</div>;
    }

    return (
        <>
            <div className="opacity-0">
                <div
                    style={{
                        paddingLeft: `${settings?.marginLeft}cm`,
                        paddingRight: `${settings?.marginRight}cm`,
                        paddingTop: `${settings?.marginTop}cm`,
                        paddingBottom: `${settings?.marginBottom}cm`,
                        height: `${pageSizes[settings?.pageSize]?.height}cm`,
                        width: `${pageSizes[settings?.pageSize]?.width}cm`,
                    }}
                    ref={printContentRef}
                >
                    <div
                        style={{
                            height: `${
                                header_footer_setup[settings?.pageSize]
                                    ?.headerHeight
                            }cm`,
                        }}
                    >
                        <img
                            src={getImageURL(
                                header_footer_setup[settings?.pageSize]
                                    ?.headerUrl
                            )}
                            alt="header"
                            className="h-full w-full"
                        />
                    </div>

                    <div style={{ height: `${bodyHeightInCm}cm` }}>
                        <div className="flex justify-between pt-2 pb-4">
                            <div className="flex items-center gap-3 font-bold text-gray-600">
                                <span>{userDetails[0]?.full_name}</span>
                                <span>{userDetails[0]?.age} Years</span>
                                <span>{userDetails[0]?.gender}</span>
                                <span>{userDetails[0]?.phone}</span>
                            </div>
                            <div>{`Visit Date :- ${moment().format("MMMM D, YYYY")}`}</div>
                        </div>
                        {settings?.pageOrdering?.map((sectionTitle, idx) => (
                            <div key={`${idx}-${sectionTitle}`}>
                                {renderSection(
                                    sectionTitle?.label,
                                    data,
                                    sectionTitle?.tagId
                                )}
                            </div>
                        ))}
                    </div>
                    {settings?.printSignature && (
                        <div
                            className="flex flex-col items-end justify-center overflow-hidden"
                            style={{ height: `${settings?.signatureHeight}cm` }}
                        >
                            <div
                                style={{
                                    width: `${settings?.signatureWidth}cm`,
                                }}
                                className="h-full"
                            >
                                <img
                                    src={getImageURL(settings?.signatureImg)}
                                    alt="..."
                                />
                                {settings?.signatureText
                                    ?.split("\n")
                                    .map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {header_footer_setup[settings?.pageSize]?.footerUrl && (
                        <div
                            style={{
                                height: `${
                                    header_footer_setup[settings?.pageSize]
                                        ?.footerHeight
                                }cm`,
                            }}
                            // className="cu-prescription-setup-section"
                        >
                            <img
                                src={getImageURL(
                                    header_footer_setup[settings?.pageSize]
                                        ?.footerUrl
                                )}
                                alt="footer"
                                className="h-full w-full"
                            />
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <>
                    {!sm ? (
                        <div className="w-full h-full flex justify-center items-center">
                            <CuModal closeModal={() => {}}>
                                <VisitPrescription
                                    setShowModal={() => {}}
                                    consultationData={consultationData}
                                    tagData={tagData}
                                    handlePrint={handlePrint}
                                />
                            </CuModal>
                        </div>
                    ) : (
                        <VisitPrescriptionSm
                            setShowModal={() => {}}
                            consultationData={consultationData}
                            tagData={tagData}
                            handlePrint={handlePrint}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default PrescriptionPDF;
