const mr = {
    translation: {
        logout: "बाहेर पडणे",
        home: "मुख्यपृष्ठ",
        appointment: "भेट",
        chat: "गप्पा",
        profile: "प्रोफाइल",
        medicalRecord: "वैद्यकीय रेकॉर्ड",
        records: " रेकॉर्ड ",
        bookNow: "त्वरा करा",
        upcoming: "आगामी",
        completed: "पूर्ण",
        callTheClinic: "क्लिनिकला कॉल करा",
        bookAppoint: "अपॉइंटमेंट बुक करा",
        youHaveNo: "तुमच्याकडे नाही",
        bookAppText: "अपॉइंटमेंट बुक करा येथे दिसेल",
        book: "बुक करा",
        noSlots: "कोणतेही स्लॉट उपलब्ध नाहीत, कृपया दुसरी तारीख वापरून पहा.",
        docUnavailable: "या दिवसासाठी डॉक्टर उपलब्ध नाहीत.",
        getAdvice: "तज्ञ डॉक्टरांकडून तज्ञ सल्ला घ्या",
        searchClinic: "क्लिनिक शोधा",
        selectClinic: "क्लिनिक निवडा",
        selectDoctor: "डॉक्टर निवडा",
        searchDoctor: "डॉक्टर शोधा",
        selectDate: "तारीख निवडा",
        next: "पुढे",
        Selectslot: "स्लॉट निवडा",
        selectPatient: "रुग्ण निवडा",
        me: "मी",
        add: "जोडा",
        phoneNumber: "फोन नंबर",
        name: "नाव",
        gender: "लिंग",
        male: "पुरुष",
        female: "महिला",
        other: "इतर",
        addFamilyMember: "कौटुंबिक सदस्य जोडा",
        addFamily: "कुटुंब जोडा",
        uploadRecords: "रेकॉर्ड अपलोड करा",
        uploadReport: "अहवाल अपलोड करा",
        showRecords: "रेकॉर्ड दाखवा",
        bookAgain: "पुन्हा बुक करा",
        update: "अद्यतनित करा",
        submit: "सबमिट करा",
        cancel: "रद्द करा",
        back: "मागे",
        uploadNew: "नवीन अपलोड करा",
        delete: "हटवा",
        uploadFiles: "फाइल अपलोड करा",
        edit_profile: "प्रोफाइल संपादित करा",
        manage_family_profile: "कुटुंब प्रोफाइल व्यवस्थापित करा",
        manage_password: "पासवर्ड व्यवस्थापित करा",
        notification_settings: "सूचना सेटिंग्ज",
        language: "भाषा",
        about_us: "आमच्याबद्दल",
        link_user: "वापरकर्ता लिंक करा",
        prescriptions: "प्रिस्क्रिप्शन",
        prescription: "प्रिस्क्रिप्शन",
        certificates: "प्रमाणपत्रे",
        bills: "बिल",
        labTests: "प्रयोगशाळा चाचण्या",
        time: "वेळ",
        age: "वय",
        dob: "जन्मतारीख",
        bloodGroup: "रक्त गट",
        street_locality: "गली/क्षेत्र",
        pincode: "पिन कोड",
        city: "शहर",
        state: "राज्य",
        country: "देश",
        alternateContactNumber: "वैकल्पिक संपर्क नंबर",
        report_type: "अहवाल प्रकार",
        signup: "साइन अप करा",
        signIn: "साइन इन करा",
        linkToResetYouPassword: "आपला पासवर्ड रीसेट करण्यासाठी लिंक",
        alreadyhaveanaccount: "आधीपासूनच खाते आहे?",
        forgotPassword: " पासवर्ड विसरलात ?",
        comingsoon: "लवकरच येत आहे",
        stayConnected: "जुडे रहा!",
        currentPassword: "वर्तमान पासवर्ड",
        newPassword: "नवीन पासवर्ड",
        confirmNewPassword: "नवीन पासवर्डची पुष्टी करा",
        loginViaPassword: "पासवर्डद्वारे लॉगिन करा",
        loginViaOTP: "OTP द्वारे लॉगिन करा",
        dontHaveAcc: "खाते नाही? ",
        dearLoginText:
            "प्रिय वापरकर्ता, आपला मोबाइल नंबर आपला वापरकर्ता आयडी म्हणून काम करतो. आपण 2 महिने किंवा अधिक काळ लॉगिन करत नसल्यास, कृपया क्लिक करा",
        switchToYourFamilyMembers: "तुमच्या कुटुंबाच्या सदस्यांवर स्विच करा ",
        viewDetails: "तपशील पहा",
        or: "किंवा",
        january: "जानेवारी",
        february: "फेब्रुवारी",
        march: "मार्च",
        april: "एप्रिल",
        may: "मे",
        june: "जून",
        july: "जुलै",
        august: "ऑगस्ट",
        september: "सप्टेंबर",
        october: "ऑक्टोबर",
        november: "नोव्हेंबर",
        december: "डिसेंबर",
        sun: "रवि",
        mon: "सोम",
        tue: "मंगळ",
        wed: "बुध",
        thu: "गुरु",
        fri: "शुक्र",
        sat: "शनि",
        morning: "सकाळ",
        evening: "संध्याकाळ",
        showless: "कमी दाखवा",
        showmore: "अधिक दाखवा",
        available: "उपलब्ध",
        slots: "स्लॉट्स",
        consultationFees: "सल्लाख शुल्क :",
        visits: "औषध व्यवस्थापन",
        selectReportType: "अहवाल प्रकार निवडा",
        reportName: "अहवालाचे नाव",
        enterReportName: "अहवालाचे नाव प्रविष्ट करा",
        note: "नोंद: हे क्षेत्र संपादनक्षम आहे. आपण अहवालाचे नाव बदलू शकता.",
        uploadFrom: "कडून अपलोड करा",
        camera: "कॅमेरा",
        images: "चित्रे",
        files: "फायल्स",
        deleteProfile: "प्रोफाइल डिलीट करा",
        email: "ईमेल",
        enter: "एंटर",
        select: "निवडा",
        reportDate: "रिपोर्टची तारीख",
        enterReportDate: "रिपोर्टची तारीख प्रविष्ट करा",
        description: "वर्णन",
        enterdescription: "वर्णन प्रविष्ट करा",
        enterOTP: "OTP प्रविष्ट करा",
        enterName: "नाव प्रविष्ट करा",
        enterTime: "वेळ प्रविष्ट करा",
        enteryourPhoneNumber: "आपला फोन नंबर प्रविष्ट करा",
        enteryourEmail: "आपला ईमेल प्रविष्ट करा",
        enteryourName: "आपलं नाव प्रविष्ट करा",
        enter4digitotpsentto: "पाठवलेला 4 अंकी OTP प्रविष्ट करा",
        mobileNumber: "मोबाइल नंबर",
        getOTP: "OTP मिळवा",
        enterNewPassword: "नवीन पासवर्ड प्रविष्ट करा",
        confirmPassword: "पासवर्डची पुष्टी करा",
        complaints: "तक्रारी",
        allergies: "अॅलर्जी",
        patient_history: "रुग्णाचा इतिहास",
        vitals: "महत्त्वाचे संकेत",
        diagnosis: "निदान",
        medicine_details: "औषधांचे तपशील",
        name: "नाव",
        instructions: "सूचना",
        dosage: "डोस",
        advice_and_direction: "सल्ला आणि मार्गदर्शन",
        test_and_investigation: "चाचणी आणि तपासणी",
        next_visit_date: "पुढील भेट तारीख",
        password: "पासवर्ड",
        firstName: "पहिले नाव",
        lastName: "आडनाव",
        andhraPradesh: "आंध्र प्रदेश",
        arunachalPradesh: "अरुणाचल प्रदेश",
        assam: "आसाम",
        bihar: "बिहार",
        chhattisgarh: "छत्तीसगड",
        goa: "गोवा",
        gujarat: "गुजरात",
        haryana: "हरियाणा",
        himachalPradesh: "हिमाचल प्रदेश",
        jharkhand: "झारखंड",
        karnataka: "कर्नाटक",
        kerala: "केरळ",
        madhyaPradesh: "मध्य प्रदेश",
        maharashtra: "महाराष्ट्र",
        manipur: "मणिपूर",
        meghalaya: "मेघालय",
        mizoram: "मिझोराम",
        nagaland: "नागालँड",
        odisha: "ओडिशा",
        punjab: "पंजाब",
        rajasthan: "राजस्थान",
        sikkim: "सिक्कीम",
        tamilNadu: "तमिळनाडू",
        telangana: "तेलंगणा",
        tripura: "त्रिपुरा",
        uttarPradesh: "उत्तर प्रदेश",
        uttarakhand: "उत्तराखंड",
        westBengal: "पश्चिम बंगाल",
        india: "भारत",
        disclaimerPoints: "अस्वीकृती मुद्दे",
        readAllThePoints: "मी सर्व मुद्दे वाचले आहेत",
        accept: "स्वीकारा",
    },
};

export default mr;
