import React, { Fragment } from "react";
import ReactDom from "react-dom";
import "../assets/css/CuScreenLoader.css";

const Backdrop = () => {
    return <div className="cu-screen-loader-backdrop"></div>;
};

const ScreenLoader = () => {
    return (
        <div className="cu-screen-loader-container">
            <div className="cu-screen-loader"></div>
        </div>
    );
};

const overLay = document.getElementById("overlays");

const CuScreenLoader = () => {
    return (
        <Fragment>
            {ReactDom.createPortal(<Backdrop />, overLay)}
            {ReactDom.createPortal(<ScreenLoader />, overLay)}
        </Fragment>
    );
};

export default CuScreenLoader;
