import { useParams } from "react-router-dom";
import InvoicePDF from "./views/InvoicePDF";
import { useState } from "react";
import PrescriptionPDF from "./views/PrescriptionPDF";

const DownloadPDF = () => {
    const { type, uuid } = useParams();
    const [isDownloaded, setDownloaded] = useState(false);

    const closePreview = () => {
        setDownloaded(true);
    };

    

    return (
        <>
            {!isDownloaded && type === "invoice" && (
                <InvoicePDF closePreview={closePreview} uuid={uuid} type={type}/>
            )}
            {!isDownloaded && type === "prescription" && (
                <PrescriptionPDF closePreview={closePreview} uuid={uuid} type={type}/>
            )}
        </>
    );
};

export default DownloadPDF;
