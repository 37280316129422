export const cufrontLogos = {
    topbar: {
        cufront:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logomark+.svg",
        ndhealthwellclinic:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
    },
    navbar: {
        cufront:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
        ndhealthwellclinic:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
    },
    ndhealthwellclinic:
        "https://cufront-assets.s3.amazonaws.com/logos/healthwellLogo.png",
    cufront: "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
};
