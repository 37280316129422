import {
    Outlet,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import "./BaseRoute.css";
import Topbar from "./layouts/Topbar";
import Navbar from "./layouts/Navbar";
import Bottombar from "./layouts/Bottombar";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useAtom } from "jotai";
import {
    changedLanguageAtom,
    selectedUserAtom,
    slotDataAtom,
    userDataAtom,
    userIdAtom,
} from "./config/globalStates";
import { useItemsFilter } from "./apis/queries";
import { GetUserMe } from "./apis/queries";
import { Logout } from "./apis/authSetup";
import CuScreenLoader from "./components/CuScreenLoader";
import { ApiEndPoint } from "./constants/constant";
import i18n from "./i18n";

const BaseRoute = () => {
    const location = useLocation();
    const md = useMediaQuery({ query: "(min-width:800px)" });
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    const [, setUserData] = useAtom(userDataAtom);
    const [, setUserID] = useAtom(userIdAtom);
    const [selectedUser, setSelectedUser] = useAtom(selectedUserAtom);
    const [, setSlotData] = useAtom(slotDataAtom);
    const [language, setLanguage] = useAtom(changedLanguageAtom);

    const [searchParams, _] = useSearchParams();
    const step = parseInt(searchParams.get("step"));
    const slotStatus = searchParams.get("slot_status");

    const hostname = window.location.hostname;

    const parts = hostname.split(".");
    let subdomain = parts[1];

    if (subdomain == undefined) {
        subdomain = "cufront";
    }

    const { data: users, isLoading } = GetUserMe(
        ApiEndPoint.USERS,
        `?action=getUserMe&frontend=True`,
        true
    );

    const logout = () => {
        Logout();
        navigate("/login");
    };

    useEffect(() => {
        if (users?.response?.data?.code === "token_not_valid") {
            logout();
        }
        if (users?.user) {
            const sortedUserData = [...users?.family_member];

            const filteredParentData = sortedUserData.filter(
                (user) =>
                    user?.is_parent === true && user?.family_id == user?.phone
            );

            const nonParentData = sortedUserData.filter(
                (user) => !filteredParentData.includes(user)
            );

            setUserData([...filteredParentData, ...nonParentData]);
            setSelectedUser(filteredParentData[0]);
            setUserID(users?.user?.id);
            i18n.changeLanguage(language);
        }
    }, [users]);

    const { data: slots } = useItemsFilter(
        "bookings/",
        `?action=getSlots&backend=True&${slotStatus}=True&patient_id=${selectedUser?.id}`,
        selectedUser?.id && slotStatus ? true : false,
        0
    );

    useEffect(() => {
        if (slots) {
            setSlotData(slots);
        }
    }, [slots]);

    return (
        <div className="cu-patient-outlet-cont">
            {md ? (
                <Navbar subdomain={subdomain} />
            ) : (
                location.pathname !== "/p/chat" && (
                    <Topbar subdomain={subdomain} />
                )
            )}
            <div className="cu-patient-outlet-main">
                <div className="cu-patient-outlet-div">
                    <Outlet />
                </div>
            </div>
            {!md && (
                <>
                    <div
                        className={`cu-patient-bottom-bar-cont ${
                            isSmallScreen &&
                            (step == 2 || step == 3 || step == 4) &&
                            "hidden"
                        }`}
                    >
                        <Bottombar />
                    </div>
                </>
            )}
            {isLoading && <CuScreenLoader />}
        </div>
    );
};

export default BaseRoute;
