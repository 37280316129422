export const Auth = {
    arrow_left: "https://cufront-assets.s3.amazonaws.com/icons+/Frame+17.png",
    unhide: "https://cufront-assets.s3.amazonaws.com/icons+/unhide.png",
    hide: "https://cufront-assets.s3.amazonaws.com/icons+/hide.png",
};

export const NAVBAR_ICONS = {
    profile:
        "https://cufront-assets.s3.amazonaws.com/icons+/profile+active.png",
};

export const Chat_Icons = {
    call: "https://cufront-assets.s3.amazonaws.com/icons+/assets/call.png",
    forward:
        "https://cufront-assets.s3.amazonaws.com/icons+/assets/forward.png",
    pin: "https://cufront-assets.s3.amazonaws.com/icons+/assets/pin.png",
    ion_arrow_back:
        "https://cufront-assets.s3.amazonaws.com/icons+/assets/ion_arrow-back.png",
    plus: "https://cufront-assets.s3.amazonaws.com/icons+/assets/plus.png",
    prescribe:
        "https://cufront-assets.s3.amazonaws.com/icons+/assets/prescribe.png",
    search: "https://cufront-assets.s3.amazonaws.com/icons+/assets/search.png",
    send: "https://cufront-assets.s3.amazonaws.com/icons+/assets/send.png",
    tv: "https://cufront-assets.s3.amazonaws.com/icons+/assets/tv.png",
    threeDot:
        "https://cufront-assets.s3.amazonaws.com/icons+/assets/threeDot.png",
};

export const cufrontIcons = {
    appointmentActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/appointment+active.svg",
    appointmentInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/appointment+inactive.svg",
    chatActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/chat+active.svg",
    chatInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/chat+inactive.svg",
    consultationActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/consultation+active.svg",
    consultationInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/consultation+inactive.svg",
    dashboardActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/dashboard+active.svg",
    dashboardInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/dashboard+inactive.svg",
    notificationActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/notifications+active.svg",
    notificationInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/notifications+inactive.svg",
    settingsActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/settings+active.svg",
    settingsInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/settings+inactive.svg",
    setupActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/setup+active.svg",
    setupInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/setup+inactive.svg",
    homeActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/home+active.png",
    homeInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/home+inactive.png",
    medRecordActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/med+records+active.png",
    medRecordInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/med+records+inactive.png",
    profileActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/profile+active.png",
    profileInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/profile+inactive.png",
    callActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/call+active.png",
    callInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/call+inactive.png",
    notificationsActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/notifications+active.png",
    notificationsInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/notifications+inactive.png",
    chatPngActive:
        "https://cufront-assets.s3.amazonaws.com/icons+/chat+active.png",
    chatPngInactive:
        "https://cufront-assets.s3.amazonaws.com/icons+/chat+inactive.png",
    public: "https://cufront-assets.s3.amazonaws.com/images/people-collaborating-remotely+1.png",
    viewDetails:
        "https://cufront-assets.s3.amazonaws.com/images/teenyicons_calendar-tick-solid.png",
    callIcon: "https://cufront-assets.s3.amazonaws.com/images/Frame+449.png",
    NoAppointment:
        "https://cufront-assets.s3.amazonaws.com/images/planning-schedule+1.png",
    NothingHere:
        "https://cufront-assets.s3.amazonaws.com/images/social-media-notifications+1.png",
    editProfile:
        "https://cufront-assets.s3.amazonaws.com/icons+/edit-profile.png",
    manageProfile:
        "https://cufront-assets.s3.amazonaws.com/icons+/manage-profile.png",
    language: "https://cufront-assets.s3.amazonaws.com/icons+/language.png",
    aboutUs: "https://cufront-assets.s3.amazonaws.com/icons+/aboutUs.png",
    logout: "https://cufront-assets.s3.amazonaws.com/icons+/logout.png",
    rightArrow:
        "https://cufront-assets.s3.amazonaws.com/images/right-arrow.png",
    backArrow:
        "https://cufront-assets.s3.amazonaws.com/images/Vector-removebg-preview.png",
    searchIcon: "https://img.icons8.com/ios-filled/50/search--v1.png",
    viewDetailsDark:
        "https://cufront-assets.s3.amazonaws.com/images/teenyicons_calendar-tick-solid+(1).png",
    delete: "https://cufront-assets.s3.amazonaws.com/icons+/delete.png",
    edit: "https://cufront-assets.s3.amazonaws.com/icons+/edit.png",
    link_user: "https://cufront-assets.s3.amazonaws.com/icons+/link.png",
    bookAppointmentOnMobile: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-12 h-12"
        >
            <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                clipRule="evenodd"
            />
        </svg>
    ),
    documentIcon: "https://cufront-assets.s3.amazonaws.com/icons+/file-img.png",
    cameraIcons:
        "https://cufront-assets.s3.amazonaws.com/icons+/camera-icon.png",
    files: "https://cufront-assets.s3.amazonaws.com/icons+/files.png",
    gallery: "https://cufront-assets.s3.amazonaws.com/icons+/gallery.png",
    galleryImg: "https://cufront-assets.s3.amazonaws.com/gallery-img.png",
};

export function PhoneIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
        </svg>
    );
}

export function MapPinIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
            <circle cx="12" cy="10" r="3" />
        </svg>
    );
}

export const doctorimage =
    "https://s3-alpha-sig.figma.com/img/5558/c498/958013de7f9b05e1cbee4be800524025?Expires=1710720000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IB073bIQf3dh~eZ887Gb5VorXKmuktZIInAb~-bB5NMEjoUkAk~BNMmuvuysgW-620Vx34xxRA20otMsYxIeSIgPjcbfxJdJ4qKaTIh19gtMPFE9luKzACA5ljgFWE7mgurUhxFbItS2jzGEB~EDSULeiADhymhq7qEhWYDypTX7TXA8byIcMmtPcZxIqqhrBC3oFKlJlR2u9TGY~7cBLQlgikR2BJ9vZLlrwm2Fsa0oCZfTz9Tvm2usZZnPErWttVDLOxlZaT2kZRr~LnbudNHFwRR6x6AhLbn62AwdkteGZ2zqnyhcPJn3oMXs0xb04ysXxF48eP-uqkWugugvZA__";

export const notFoundPng =
    "https://cufront-assets.s3.amazonaws.com/images/404.png";
