import axios from "axios";

const getBaseAndToken = () => {
    const hostname = window.location.hostname;
    const config = {
        localhost: {
            WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
            BASE_URL: process.env.REACT_APP_BASE_URL,
            ADMIN_TOKEN: process.env.REACT_APP_AUTH_TOKEN,
            PATIENT_ROLE: process.env.REACT_APP_PATIENT_ROLE,
        },
        default: {
            WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
            BASE_URL: process.env.REACT_APP_BASE_URL,
            ADMIN_TOKEN: process.env.REACT_APP_AUTH_TOKEN,
            PATIENT_ROLE: process.env.REACT_APP_PATIENT_ROLE,
        },
    };

    const { BASE_URL, ADMIN_TOKEN, PATIENT_ROLE, WEBSOCKET_URL } =
        config[hostname] || config["default"];

    return { BASE_URL, ADMIN_TOKEN, PATIENT_ROLE, WEBSOCKET_URL };
};

const { BASE_URL, ADMIN_TOKEN, PATIENT_ROLE, WEBSOCKET_URL } =
    getBaseAndToken();
export { BASE_URL, ADMIN_TOKEN, PATIENT_ROLE, WEBSOCKET_URL };

axios.defaults.baseURL = BASE_URL;

export const restoreAccessToken = () => {
    const token = localStorage.getItem("access_token");
    return token;
};

export const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

publicAxios.interceptors.response.use(
    function (res) {
        return res.data;
    },
    async function (err) {
        return Promise.reject(err);
    }
);

export const secureAxios = axios.create({
    baseURL: BASE_URL,
    headers: {},
});

if (localStorage.getItem("access_token")) {
    setAuthToken(localStorage.getItem("access_token"));
}

secureAxios.interceptors.response.use(
    function (res) {
        return res.data;
    },
    function (err) {
        const originalRequest = err.config;
        if (
            (err?.response?.status === 403 || err?.response?.status === 401) &&
            !originalRequest._retry
        ) {
            const token = restoreAccessToken();
            secureAxios.defaults.headers.common["Authorization"] =
                "Bearer " + token;

            return Promise.reject(err);
        }
        return Promise.reject(err);
    }
);

export const adminAxios = axios.create({
    baseURL: BASE_URL,
    headers: {},
});

setAdminToken(ADMIN_TOKEN);

adminAxios.interceptors.response.use(
    function (res) {
        return res.data;
    },
    function (err) {
        const originalRequest = err.config;
        if (
            (err?.response?.status === 403 || err?.response?.status === 401) &&
            !originalRequest._retry
        ) {
            const token = ADMIN_TOKEN;

            adminAxios.defaults.headers.common["Authorization"] =
                "Bearer " + token;

            return Promise.reject(err);
        }
        return Promise.reject(err);
    }
);

export function setAdminToken(token) {
    if (token) {
        adminAxios.defaults.headers.common["Authorization"] =
            `Bearer ${ADMIN_TOKEN}`;
    } else {
        delete adminAxios.defaults.headers.common["Authorization"];
    }
}

export function setAuthToken(token) {
    if (token) {
        secureAxios.defaults.headers.common["Authorization"] =
            `Bearer ${token}`;
    } else {
        delete secureAxios.defaults.headers.common["Authorization"];
    }
}

export function Logout() {
    setAuthToken(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("selectedLanguage");
}
