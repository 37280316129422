import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import BaseRoute from "./BaseRoute";
import ProtectedRoute from "./ProtectedRoute";
// import Language from "./pages/Language";
// import { useAtom } from "jotai";
// import { changedLanguageAtom } from "./config/globalStates";
import i18n from "./i18n";
import DownloadPDF from "./download-PDF/DownloadPDF";

// Lazy load components
const Home = lazy(() => import("./pages/Home"));
const Records = lazy(() => import("./pages/Records"));
const Profile = lazy(() => import("./pages/Profile"));
// const Notifications = lazy(() => import("./pages/Notifications"));
const Authentication = lazy(() => import("./authentication/Authentication"));
const Appointment = lazy(() => import("./pages/Appointment"));
const BookingSlot = lazy(() => import("./pages/BookingSlot"));
const CuButton = lazy(() => import("./components/CuButton"));
// const EditProfile = lazy(() => import("./pages/EditProfile"));
// const ManageProfile = lazy(() => import("./pages/ManageProfile"));
// const LinkUser = lazy(() => import("./pages/LinkUser"));
const EmptyState = lazy(() => import("./components/EmptyState"));
// const ManagePassword = lazy(() => import("./pages/ManagePassword"));

const Routing = () => {
    // const [language, setLanguage] = useAtom(changedLanguageAtom);

    useEffect(() => {
        i18n.changeLanguage(navigator.language);
    }, []);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/login" element={<Authentication />} />
                <Route path="/download/:type/:uuid" element={<DownloadPDF/>}/>
                <Route path="/p" element={<ProtectedRoute Cmp={BaseRoute} />}>
                    <Route index element={<Home />} />
                    <Route path="appointment" element={<Appointment />} />
                    <Route path="clinics" element={<BookingSlot />} />
                    <Route path="clinics/*" element={<BookingSlot />} />
                    <Route path="button" element={<CuButton />} />
                    <Route path="record" element={<Records />} />
                    <Route path="profile" element={<Profile />} />
                    {/* <Route path="profile/edit/:id" element={<EditProfile />} /> */}
                    {/* <Route path="profile/manage" element={<ManageProfile />} /> */}
                    {/* <Route
                        path="manage-password"
                        element={<ManagePassword />}
                    /> */}
                    {/* <Route path="notifications" element={<Notifications />} /> */}
                    {/* <Route path="link_user" element={<LinkUser />} /> */}
                    <Route path="chat" element={<EmptyState />} />
                </Route>
                <Route path="*" element={<Navigate to="/p" />} />
            </Routes>
        </Suspense>
    );
};

export default Routing;
