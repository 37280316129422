import React, { useState } from "react";
import MedicalRecordCard from "../components/MedicalRecordCard";
import CuModal from "./CuModal";
import CuScreenLoader from "../components/CuScreenLoader";
import { cufrontIcons } from "../assets/icons/icons";
import CuStatusMessage from "./CuStatusMessage";
import { useMediaQuery } from "react-responsive";
import "../assets/css/prescriptions.css";
import { useDeleteItem } from "../apis/mutations";
import Confirmation from "../components/Confirmation";
import ImagePreview from "../components/ImagePreview";
import moment from "moment";

const Prescriptions = ({ data, isLoading }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedDeleteId, setSelectedDeleteId] = useState("");
    const [mainImage, setMainImage] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const sm = !useMediaQuery({ query: "(min-width:1024px)" });

    const [openPdfViewer, setOpenPdfViewer] = useState(false);

    const [pdfData, setPdfData] = useState();

    // const handleClick = (data) => {
    //     setModalData(data);
    //     setShowModal(true);
    // };

    const handleOpenPdfViewer = (item) => {
        setPdfData(item);
        setOpenPdfViewer(!openPdfViewer);
    };

    const deleteRecordsMutation = useDeleteItem("users/");

    const handleDelete = () => {
        const payload = {
            action: "delUserRecords",
            frontend: "True",
            id: selectedDeleteId,
            clinic_id: 1,
        };
        deleteRecordsMutation.mutate(
            { data: payload },
            {
                onSuccess: () => {
                    setSelectedDeleteId("");
                    setOpenModal(false);
                },
                onError: () => {
                    setSelectedDeleteId("");
                },
            }
        );
    };

    return (
        <>
            {isLoading ? (
                <CuScreenLoader />
            ) : (
                <div className=" h-full">
                    {data && Array.isArray(data) && data?.length > 0 ? (
                        data.map((item, i) => (
                            <div className="mt-5" key={i}>
                                <MedicalRecordCard
                                    item={item}
                                    title="Prescriptions"
                                    date={moment(item?.date_created).format(
                                        "DD MMM YYYY"
                                    )}
                                    // onClick={() => handleClick(item)}
                                    handleOpenPdfViewer={() =>
                                        handleOpenPdfViewer(item)
                                    }
                                    handleDelete={() => (
                                        setSelectedDeleteId(item?.id),
                                        setOpenModal(true)
                                    )}
                                />
                            </div>
                        ))
                    ) : (
                        <CuStatusMessage
                            heading={"You don’t have any prescription record"}
                            description={
                                "To add Prescription Records, Tap on the Upload New Button."
                            }
                            img={cufrontIcons?.NoAppointment}
                        />
                    )}
                    {openPdfViewer && (
                        <ImagePreview
                            uploadedFile={
                                pdfData?.files?.flat().length > 0
                                    ? pdfData.files.flat()
                                    : [
                                          {
                                              filename: pdfData?.files_id,
                                              url: pdfData?.files_id,
                                          },
                                      ]
                            }
                            mainImage={mainImage}
                            setMainImage={setMainImage}
                            setShowPreview={setOpenPdfViewer}
                            setSelectedIndex={setSelectedIndex}
                            selectedIndex={selectedIndex}
                        />
                    )}

                    {openModal && (
                        <CuModal closeModal={() => setOpenModal(false)}>
                            <Confirmation
                                heading={"Delete Prescription"}
                                onClick={handleDelete}
                                onCancel={() => setOpenModal(false)}
                                loading={deleteRecordsMutation.isLoading}
                            />
                        </CuModal>
                    )}
                </div>
            )}
        </>
    );
};

export default Prescriptions;
