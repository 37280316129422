import "../assets/css/Topbar.css";
import React, { useState } from "react";
// import { useNavigate } from "react-router";
import { cufrontLogos } from "../assets/logos/logos";
import { selectedUserAtom, userDataAtom } from "../config/globalStates";
import { useAtom } from "jotai";
import CuAvatar from "../components/CuAvatar";
import { getImageURL } from "../apis/image";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Topbar = ({ subdomain }) => {
    const { t } = useTranslation();
    const [userData] = useAtom(userDataAtom);
    const [selectedUser, setSelectedUser] = useAtom(selectedUserAtom);
    const [isViewOptions, setIsViewOptions] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [searchParams] = useSearchParams();
    // const clinicId = searchParams.get("clinic_id");
    // const doctor_id = searchParams.get("doctor_id");
    const step = parseInt(searchParams.get("step"));

    const sortedUserData = [...userData];

    sortedUserData.sort((a, b) => {
        if (a.phone === a.family_id && b.phone !== b.family_id) {
            return -1;
        } else if (b.phone === b.family_id && a.phone !== a.family_id) {
            return 1;
        } else {
            if (a.isParent === true && b.isParent !== true) {
                return -1;
            } else if (a.isParent !== true && b.isParent === true) {
                return 1;
            } else {
                return 0;
            }
        }
    });

    // const location = useLocation();
    // const navigate = useNavigate();

    const handleViewOptions = () => {
        setIsViewOptions((prev) => !prev);
        setIsOpen(true);
    };

    const handleSelectOption = (i) => {
        setSelectedUser(sortedUserData[i]);
        setTimeout(() => {
            setIsViewOptions(false);
        }, 400);
        setIsOpen(!isOpen);
    };

    const toggleContainer = () => {
        setTimeout(() => {
            setIsViewOptions(false);
        }, 400);
        setIsOpen(!isOpen);
    };

    // const handleHomeNavigate = () => {
    //     if (subdomain !== "cufront") {
    //         window.location.href = "https://ndhealthwellclinic.com/";
    //     } else {
    //         navigate("/p");
    //     }
    // };

    return (
        <div className={`cu-top-bar-main  ${step > 0 && "hidden"}`}>
            <div>
                <div className="flex justify-between items-center h-full">
                    <div className="cu-top-bar-cufrontlogo">
                        <img src={cufrontLogos.topbar[subdomain]} alt="Logo" />
                    </div>
                    <div className="cu-top-bar-notifications">
                        <div className="flex cursor-pointer">
                            <div
                                onClick={handleViewOptions}
                                className="rounded-lg"
                            >
                                {selectedUser?.profile_image ? (
                                    <img
                                        className="w-[30px] h-[30px] rounded-[5px] "
                                        src={getImageURL(
                                            selectedUser?.profile_image
                                        )}
                                        alt="..."
                                    />
                                ) : (
                                    <CuAvatar
                                        name={selectedUser?.full_name[0]}
                                    />
                                )}
                            </div>
                            <div className="flex"></div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end items-center"></div>
                {isOpen && isViewOptions && (
                    <div onClick={toggleContainer} className="overlay"></div>
                )}

                {isViewOptions && (
                    <>
                        <div
                            className={`family-options-cont ${
                                isOpen ? "" : "closed"
                            }`}
                        >
                            <h2 className="pt-2 pb-3 leading-10">
                                {t("switchToYourFamilyMembers")}
                            </h2>
                            <div className="flex overflow-x-scroll w-[100%] gap-3">
                                {sortedUserData?.map((user, i) => (
                                    <div
                                        className={`flex-shrink-0 rounded-lg border ${
                                            selectedUser?.full_name ===
                                                user?.full_name &&
                                            "bg-[#C7EAC9]"
                                        }`}
                                        key={user?.id}
                                        onClick={() => handleSelectOption(i)}
                                    >
                                        <div className="flex justify-center">
                                            {user?.profile_image ? (
                                                <img
                                                    className="w-[37px] h-[37px] rounded-full"
                                                    src={getImageURL(
                                                        user?.profile_image
                                                    )}
                                                    alt="..."
                                                />
                                            ) : (
                                                <CuAvatar
                                                    name={user?.full_name[0]}
                                                />
                                            )}
                                        </div>
                                        <p className="text-[14px] leading-5 text-center p-1 mt-2">
                                            {user?.full_name}
                                        </p>
                                        <p className="text-[12px] leading-3 text-center">
                                            {user?.age} years ,{" "}
                                            <span>{user?.gender}</span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Topbar;
