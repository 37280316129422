import React, { useState } from "react";
import { useItemsFilter } from "../apis/queries";
import moment from "moment";
import { ApiEndPoint, filterByTagType } from "../constants/constant";
import { selectedUserAtom } from "../config/globalStates";
import { useAtom } from "jotai";
import CuScreenLoader from "../components/CuScreenLoader";
import MedicalRecordCard from "../components/MedicalRecordCard";
import CuStatusMessage from "../layouts/CuStatusMessage";
import CuModal from "../layouts/CuModal";
import { IoIosClose } from "react-icons/io";
import { cufrontIcons } from "../assets/icons/icons";
import { useMediaQuery } from "react-responsive";
import VisitPrescriptionSm from "../layouts/VisitPrescriptionSm";
import VisitPrescription from "../layouts/VisitPrescription";

const Visits = () => {
    const sm = !useMediaQuery({ query: "(min-width:768px)" });
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [selectedUser] = useAtom(selectedUserAtom);
    // const [openModal, setOpenModal] = useState(false);
    const { data: consultationData, isLoading: dataLoading } = useItemsFilter(
        ApiEndPoint.QP_CM_CONSULTATION_DATA,
        `?action=getConsultationData&frontend=True&clinic_id=1&id=${modalData?.consultation_data[0]?.id}`,
        modalData?.consultation_data[0]?.id ? true : false
    );

    const { data: allData, isLoading } = useItemsFilter(
        ApiEndPoint.QM_SC_SLOTS,
        `?action=getSlots&backend=True&clinic_id=1&patient_id=${selectedUser?.id}&clinic=true&completed=True`,
        selectedUser?.id ? true : false
    );

    const { data: tagData } = useItemsFilter(
        ApiEndPoint.MEDICINE_TAG_DATA,
        `?action=getTagTypes&clinic_id=1&backend=True`
    );

    const getTagId = (label) => {
        const setting = tagData?.find(
            (item) => item.name.toLowerCase() === label.toLowerCase()
        );
        return setting ? setting.id : null;
    };

    const handleClick = (data) => {
        setModalData(data);
        setShowModal(true);
    };

    return (
        <>
            {isLoading ? (
                <CuScreenLoader />
            ) : (
                <div className=" h-full">
                    {allData &&
                    Array.isArray(allData) &&
                    allData?.length > 0 ? (
                        allData.map((item, i) => (
                            <div className="mt-5" key={i}>
                                <MedicalRecordCard
                                    item={item}
                                    title="Prescriptions"
                                    date={moment(item?.date_created).format(
                                        "DD MMM YYYY"
                                    )}
                                    onClick={() => handleClick(item)}
                                />
                            </div>
                        ))
                    ) : (
                        <CuStatusMessage
                            heading={"You don’t have any visit record"}
                            // description={
                            //     "To add Prescription Records, Tap on the Upload New Button."
                            // }
                            img={cufrontIcons?.NoAppointment}
                        />
                    )}
                    {dataLoading ? (
                        <CuScreenLoader />
                    ) : (
                        <>
                            {showModal && (
                                <>
                                    {!sm ? (
                                        <div className="w-full h-full flex justify-center items-center">
                                            <CuModal
                                                closeModal={() =>
                                                    setShowModal(false)
                                                }
                                            >
                                                <VisitPrescription
                                                    setShowModal={setShowModal}
                                                    consultationData={
                                                        consultationData
                                                    }
                                                    tagData={tagData}
                                                />
                                            </CuModal>
                                        </div>
                                    ) : (
                                        <VisitPrescriptionSm
                                            setShowModal={setShowModal}
                                            consultationData={consultationData}
                                            tagData={tagData}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default Visits;
