import React from "react";
import { useNavigate } from "react-router";

const CuStatusMessage = ({ heading, description, button, route, img }) => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-full flex justify-center items-center">
            <div>
                <p className="text-lg font-bold text-center mt-5">{heading}</p>
                <div className="w-[70%] m-auto text-center">{description}</div>
                {button && (
                    <div className="text-center h-[44px] mt-6">
                        <button
                            className=" w-[244px] bg-black text-white h-full rounded border-[0.5px] border-[#00000033]"
                            onClick={() => navigate(route)}
                        >
                            {button}
                        </button>
                    </div>
                )}
                <div className=" m-auto w-full h-[301px] lg:w-[600px] lg:h-[500px] sm:w-[450px] sm:h-[350px] xl:w-[700px] xl:h-[606px] 2xl:w-[]">
                    <img className="w-full" src={img} alt="" />
                </div>
            </div>
        </div>
    );
};

export default CuStatusMessage;
