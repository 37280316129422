import "../assets/css/Botombar.css";
import React, { useState } from "react";
import { PatientsSidebarOptions } from "../constants/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Bottombar = () => {
    const [activeTab, setActiveTab] = useState("1"); // State to manage active tab
    const navigate = useNavigate();
    const patientsSidebarOptions = PatientsSidebarOptions();
    const handleTabClick = (tabId, route) => {
        setActiveTab(tabId);
        navigate(route);
    };

    return (
        <div className={`cu-patient-bottom-bar`}>
            {patientsSidebarOptions.map((option) => (
                <div
                    key={option.id}
                    className={`cu-patient-bottom-div ${
                        activeTab === option.id
                            ? "cu-bottom-bar-option"
                            : "cu-bottom-bar-option-active"
                    }`}
                    onClick={() => handleTabClick(option.id, option.route)}
                >
                    <span>
                        {activeTab === option?.id
                            ? option?.activeIcon
                            : option?.inactiveIcon}
                    </span>
                    <div className="text-xs">{option.name}</div>
                </div>
            ))}
        </div>
    );
};

export default Bottombar;
