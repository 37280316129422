import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { selectedUserAtom } from "../config/globalStates";
import MedicalRecordCard from "../components/MedicalRecordCard";
import moment from "moment";
import CuScreenLoader from "../components/CuScreenLoader";
import CuStatusMessage from "./CuStatusMessage";
import { cufrontIcons } from "../assets/icons/icons";
import { useDeleteItem } from "../apis/mutations";
import { useMediaQuery } from "react-responsive";
import CuModal from "./CuModal";
import Confirmation from "../components/Confirmation";
import CuMobileModal from "../components/CuMobileModal";
import { getImageURL } from "../apis/image";
import ImagePreview from "../components/ImagePreview";
const Certificates = ({ data, isLoading }) => {
    const sm = !useMediaQuery({ query: "(min-width:768px)" });
    const [certificateData, setCertificateData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedDeleteId, setSelectedDeleteId] = useState("");
    const [selectedUser] = useAtom(selectedUserAtom);
    const [openPdfViewer, setOpenPdfViewer] = useState(false);
    const [pdfData, setPdfData] = useState();
    const [mainImage, setMainImage] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleClick = () => {};

    useEffect(() => {
        if (data) {
            setCertificateData(data);
        }
    }, [data]);

    const deleteRecordsMutation = useDeleteItem("users/");

    const handleDelete = () => {
        const payload = {
            action: "delUserRecords",
            frontend: "True",
            id: selectedDeleteId,
            clinic_id: 1,
        };
        deleteRecordsMutation.mutate(
            { data: payload },
            {
                onSuccess: () => {
                    setOpenModal(false);
                    setSelectedDeleteId("");
                },
                onError: () => {
                    setSelectedDeleteId("");
                },
            }
        );
    };

    const handleOpenPdfViewer = (item) => {
        setPdfData(item);
        setOpenPdfViewer(!openPdfViewer);
    };

    return (
        <div className="w-full h-full ">
            {isLoading ? (
                <CuScreenLoader />
            ) : (
                <>
                    {certificateData?.length > 0 ? (
                        <div className="mt-5">
                            {certificateData?.map((item, i) => (
                                <div className="mt-5" key={i}>
                                    <MedicalRecordCard
                                        item={item}
                                        title="Prescriptions"
                                        date={moment(item?.date_created).format(
                                            "DD MMM YYYY"
                                        )}
                                        onClick={() => handleClick(item)}
                                        handleOpenPdfViewer={() =>
                                            handleOpenPdfViewer(item)
                                        }
                                        handleDelete={() => (
                                            setSelectedDeleteId(item?.id),
                                            setOpenModal(true)
                                        )}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <CuStatusMessage
                            heading={"You don’t have any Certificate"}
                            description={
                                "To add Certificates, Tap on the Upload New Button."
                            }
                            img={cufrontIcons?.NoAppointment}
                        />
                    )}
                </>
            )}
            {!!openModal && (
                <CuModal closeModal={() => setOpenModal(false)}>
                    <Confirmation
                        heading={"Delete Certificates"}
                        onClick={handleDelete}
                        onCancel={() => setOpenModal(false)}
                        loading={deleteRecordsMutation.isLoading}
                    />
                </CuModal>
            )}
            {openPdfViewer && (
                <ImagePreview
                    uploadedFile={
                        pdfData?.files?.flat().length > 0
                            ? pdfData.files.flat()
                            : [
                                  {
                                      filename: pdfData?.files_id,
                                      url: pdfData?.files_id,
                                  },
                              ]
                    }
                    mainImage={mainImage}
                    setMainImage={setMainImage}
                    setShowPreview={setOpenPdfViewer}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                />
            )}
        </div>
    );
};

export default Certificates;
