const hi = {
    translation: {
        logout: "लॉग आउट",
        home: "होम",
        appointment: "अपॉइंटमेंट",
        chat: "बातचीत",
        profile: "प्रोफ़ाइल",
        medicalRecord: "मेडिकल रिकॉर्ड",
        bookNow: "अभी बुक करें",
        upcoming: "आगामी",
        completed: "पूरा हो गया",
        callTheClinic: "क्लिनिक में कॉल करें",
        bookAppoint: "अपॉइंटमेंट बुक करें",
        youHaveNo: "आपके पास नहीं हैं",
        bookAppText: "अपॉइंटमेंट बुक करें यहां दिखाई देगा",
        book: "बुक करें",
        noSlots:
            "कोई स्लॉट उपलब्ध नहीं है, कृपया किसी अन्य तिथि का प्रयास करें।",
        docUnavailable: "इस दिन डॉक्टर उपलब्ध नहीं हैं.",
        getAdvice: "विशेषज्ञ डॉक्टरों से विशेषज्ञ सलाह प्राप्त करें",
        searchClinic: "क्लिनिक खोजें",
        selectClinic: "क्लिनिक चुनें",
        selectDoctor: "डॉक्टर चुनें",
        searchDoctor: "डॉक्टर खोजें",
        selectDate: "तारीख चुनें",
        next: "अगला",
        Selectslot: "स्लॉट चुनें",
        selectPatient: "मरीज़ चुनें",
        me: "मैं",
        add: "जोड़ें",
        phoneNumber: "फोन नंबर",
        name: "नाम",
        gender: "लिंग",
        male: "पुरुष",
        female: "महिला",
        other: "अन्य",
        addFamilyMember: "परिवार के सदस्य को जोड़ें",
        addFamily: "परिवार जोड़ें",
        uploadRecords: "रिकॉर्ड अपलोड करें",
        records: " रिकॉर्ड ",
        uploadReport: "रिपोर्ट अपलोड करें",
        showRecords: "रिकॉर्ड दिखाएं",
        bookAgain: "फिर से बुक करें",
        update: "अपडेट करें",
        submit: "सबमिट करें",
        cancel: "रद्द करें",
        back: "वापस",
        uploadNew: "नया अपलोड करें",
        delete: "हटाएं",
        uploadFiles: "फाइल अपलोड करें",
        edit_profile: "प्रोफ़ाइल संपादित करें",
        manage_family_profile: "परिवार की प्रोफ़ाइल प्रबंधित करें",
        manage_password: "पासवर्ड प्रबंधित करें",
        notification_settings: "सूचना सेटिंग्स",
        language: "भाषा",
        about_us: "हमारे बारे में",
        link_user: "उपयोगकर्ता लिंक करें",
        prescriptions: "प्रिस्क्रिप्शन",
        certificates: "प्रमाण पत्र",
        bills: "बिल",
        labTests: "प्रयोगशाला परीक्षण",
        time: "समय",
        age: "आयु",
        dob: "जन्म तिथि",
        bloodGroup: "रक्त समूह",
        street_locality: "गली/क्षेत्र",
        pincode: "पिन कोड",
        city: "शहर",
        state: "राज्य",
        country: "देश",
        alternateContactNumber: "वैकल्पिक संपर्क नंबर",
        report_type: "रिपोर्ट प्रकार",
        signup: "साइन अप करें",
        signIn: "साइन इन करें",
        linkToResetYouPassword: "आपके पासवर्ड को रीसेट करने के लिए लिंक",
        alreadyhaveanaccount: "पहले से ही एक खाता है?",
        forgotPassword: " पासवर्ड भूल गए ?",
        comingsoon: "जल्द आ रहा है",
        stayConnected: "जुड़े रहें!",
        currentPassword: "वर्तमान पासवर्ड",
        newPassword: "नया पासवर्ड",
        confirmNewPassword: "नए पासवर्ड की पुष्टि करें",
        loginViaPassword: "पासवर्ड के माध्यम से लॉगिन करें",
        loginViaOTP: "OTP के माध्यम से लॉगिन करें",
        dontHaveAcc: "खाता नहीं है?   ",
        dearLoginText:
            "प्रिय उपयोगकर्ता, आपका मोबाइल नंबर आपका उपयोगकर्ता आईडी है। यदि आप 2 महीने या उससे अधिक समय से लॉगिन नहीं कर रहे हैं, तो कृपया क्लिक करें",
        switchToYourFamilyMembers: "अपने परिवार के सदस्यों पर स्विच करें ",
        viewDetails: "विवरण देखें",
        or: "या",
        january: "जनवरी",
        february: "फरवरी",
        march: "मार्च",
        april: "अप्रैल",
        may: "मई",
        june: "जून",
        july: "जुलाई",
        august: "अगस्त",
        september: "सितंबर",
        october: "अक्टूबर",
        november: "नवंबर",
        december: "दिसंबर",
        Sun: "रवि",
        Mon: "सोम",
        Tue: "मंगल",
        Wed: "बुध",
        Thu: "गुरु",
        Fri: "शुक्र",
        Sat: "शनि",
        morning: "सुबह के",
        evening: "शाम के",
        showless: "कम दिखाएं",
        showmore: "अधिक दिखाएं",
        available: "उपलब्ध",
        slots: "स्लॉट्स",
        consultationFees: "सलाहकारी शुल्क :",
        visits: "चिकित्सा रिपोर्टें ",
        selectReportType: "रिपोर्ट प्रकार चुनें",
        reportName: "रिपोर्ट का नाम",
        enterReportName: "रिपोर्ट का नाम दर्ज करें",
        note: "नोट: यह फ़ील्ड संपादन योग्य है। आप रिपोर्ट का नाम बदल सकते हैं।",
        uploadFrom: "इससे अपलोड करें",
        camera: "कैमरा",
        images: "तस्वीरें",
        files: "फ़ाइलें",
        deleteProfile: "प्रोफ़ाइल हटाएं",
        email: "ईमेल",
        enter: "एंटर",
        select: "चुनें",
        reportDate: "रिपोर्ट की तारीख",
        enterReportDate: "रिपोर्ट की तारीख दर्ज करें",
        description: "विवरण",
        enterdescription: "विवरण दर्ज करें",
        enterOTP: "OTP दर्ज करें",
        enterName: "नाम दर्ज करें",
        enterTime: "समय दर्ज करें",
        enteryourPhoneNumber: "अपना फोन नंबर दर्ज करें",
        enteryourEmail: "अपना ईमेल दर्ज करें",
        enteryourName: "अपना नाम दर्ज करें",
        enter4digitotpsentto: "भेजा गया 4 अंकों का OTP दर्ज करें",
        mobileNumber: "मोबाइल नंबर",
        getOTP: "OTP प्राप्त करें",
        enterNewPassword: "नया पासवर्ड डालें",
        confirmPassword: "पासवर्ड की पुष्टि करें",
        prescription: "प्रिस्क्रिप्शन",
        complaints: "शिकायतें",
        allergies: "एलर्जी",
        patient_history: "रोगी का इतिहास",
        vitals: "महत्वपूर्ण संकेत",
        diagnosis: "निदान",
        medicine_details: "दवा का विवरण",
        name: "नाम",
        instructions: "निर्देश",
        dosage: "खुराक",
        advice_and_direction: "सलाह और दिशा-निर्देश",
        test_and_investigation: "परीक्षण और जांच",
        next_visit_date: "अगली विज़िट दिनांक",
        password: "पासवर्ड",
        firstName: "पहला नाम",
        lastName: "अंतिम नाम",
        andhraPradesh: "आंध्र प्रदेश",
        arunachalPradesh: "अरुणाचल प्रदेश",
        assam: "असम",
        bihar: "बिहार",
        chhattisgarh: "छत्तीसगढ़",
        goa: "गोवा",
        gujarat: "गुजरात",
        haryana: "हरियाणा",
        himachalPradesh: "हिमाचल प्रदेश",
        jharkhand: "झारखंड",
        karnataka: "कर्नाटक",
        kerala: "केरल",
        madhyaPradesh: "मध्य प्रदेश",
        maharashtra: "महाराष्ट्र",
        manipur: "मणिपुर",
        meghalaya: "मेघालय",
        mizoram: "मिजोरम",
        nagaland: "नागालैंड",
        odisha: "ओडिशा",
        punjab: "पंजाब",
        rajasthan: "राजस्थान",
        sikkim: "सिक्किम",
        tamilNadu: "तमिलनाडु",
        telangana: "तेलंगाना",
        tripura: "त्रिपुरा",
        uttarPradesh: "उत्तर प्रदेश",
        uttarakhand: "उत्तराखंड",
        westBengal: "पश्चिम बंगाल",
        india: "भारत",
        disclaimerPoints: "अस्वीकरण बिंदु",
        readAllThePoints: "मैंने सभी बिंदु पढ़ लिए हैं",
        accept: "स्वीकार करें",
    },
};

export default hi;
