import React, { Component, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ProtectedRoute(props) {
    const navigate = useNavigate("/");
    let Cmp = props.Cmp;
    useEffect(() => {
        if (!localStorage.getItem("access_token")) {
            navigate("/login");
        }
    });
    return (
        <div>
            <Cmp />
        </div>
    );
}
