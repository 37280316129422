import { useQuery } from "react-query";
import { adminAxios, publicAxios, secureAxios } from "./authSetup";
import { toast } from "react-toastify";

export const useUsersdata = (query, enabled) => {
    return useQuery(
        ["users"],
        () =>
            adminAxios
                .get(query)
                .then((res) => res.data)
                .catch((err) => err),
        {
            enabled: enabled,
        }
    );
};

export const GetUserMe = (collection, query, enabled) => {
    return useQuery(
        [collection],
        () =>
            secureAxios
                .get(`${collection}/${query}`)
                .then((res) => res.data)
                .catch((err) => err),
        {
            enabled: enabled,
            cacheTime: 0,
        }
    );
};

export const useItems = (collection, preset, enabled) => {
    return useQuery(
        ["items", collection],
        () => secureAxios.get(`/items/${collection}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

// export const useItemsFilter = (collection, queryString, enabled) => {
//   return useQuery(
//     ["items", collection, queryString],
//     () =>
//       secureAxios
//         .get(`/items/${collection}${queryString ? `${queryString}` : ``}`)
//         .then((res) => (queryString.includes("filter_count") ? res : res.data)),
//     {
//       enabled: enabled,
//     }
//   );
// };

export const useGetOtp = (collection, queryString, enabled) => {
    return useQuery(
        [collection, queryString],
        () =>
            publicAxios
                .get(`/${collection}${queryString ? `${queryString}` : ``}`)
                .then((res) =>
                    queryString.includes("filter_count") ? res : res.data
                )
                .catch((err) => toast.error(err.response.data.message)),
        {
            enabled: enabled,
            cacheTime: 0,
        }
    );
};

export const useItemsFilter = (
    collection,
    queryString,
    enabled,
    cacheTime = 300000
) => {
    return useQuery(
        [collection, queryString],
        () =>
            secureAxios
                .get(`/${collection}${queryString ? `${queryString}` : ``}`)
                .then((res) =>
                    queryString.includes("filter_count") ? res : res.data
                ),
        {
            enabled: enabled,
            cacheTime: cacheTime,
        }
    );
};

export const useUserFilter = (collection, queryString, enabled) => {
    return useQuery(
        ["items", collection, queryString],
        () =>
            secureAxios
                .get(`${collection}${queryString}`)
                .then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const Editprofiledata = (enabled) => {
    return useQuery(
        ["user_data"],
        () =>
            secureAxios
                .get(
                    `/users/me?fields=first_name,last_name,qm_sc_users_personal_details.*`
                )
                .then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useUserItemsFilter = (
    collection,
    queryString,
    enabled,
    extraQuery = null
) => {
    return useQuery(
        ["items", collection, queryString, extraQuery],
        () =>
            secureAxios.get(`${collection}${queryString}`).then((res) => {
                return queryString.includes("filter_count") ? res : res.data;
            }),
        {
            enabled: enabled,
        }
    );
};

//Takin all user data for forget password purpose.

export const useNoticedata = (collection, enabled) => {
    return useQuery(
        ["items", collection],
        () => secureAxios.get(`items/${collection}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};
