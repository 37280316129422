import moment from "moment";
import React from "react";
import { IoIosClose } from "react-icons/io";
import { filterByTagType } from "../constants/constant";
import { useTranslation } from "react-i18next";

const VisitPrescription = ({
    setShowModal,
    consultationData,
    tagData,
    handlePrint,
}) => {
    const { t } = useTranslation();

    const getTagId = (label) => {
        const setting = tagData?.find(
            (item) => item.name.toLowerCase() === label.toLowerCase()
        );
        return setting ? setting.id : null;
    };
    return (
        <div className="h-full overflow-auto text-[10.85px] laptop:text-[10.85px] desktop:text-[14.81px] large:text-[15.81px] xlarge:text-[22.22px] leading-[12.85px] laptop:leading-[13.63px] desktop:leading-[17.04px] large:leading-[18.18px] xlarge:leading-[25.55px]">
            <div className="w-5 h-5 cursor-pointer absolute right-4 top-2">
                <IoIosClose size={18} onClick={() => setShowModal(false)} />
            </div>
            <div className="w-full flex my-2 py-4 border-b border-[#00000033] gap-2">
                <div className="w-[65%] flex-1 flex gap-4 flex-wrap pr-4">
                    <span>{consultationData?.personal_details?.full_name}</span>
                    <span>{consultationData?.personal_details?.age}</span>
                    <span className="uppercase">
                        {
                            consultationData?.personal_details?.gender?.split(
                                ""
                            )[0]
                        }
                    </span>{" "}
                    <span>{consultationData?.personal_details?.phone}</span>
                </div>
                <div className=" flex justify-center gap-3">
                    <span>Visit :-</span>
                    <span>
                        {moment(consultationData?.date_created).format(
                            "MMMM DD, YYYY"
                        )}
                    </span>
                </div>
            </div>

            <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                <div className="w-[20%] text-[#6D6D6D] flex justify-start items-center">
                    {t("complaints")}
                </div>
                <div className="flex-1 flex items-center">
                    <div className="lowercase mb-1 laptop:leading-[17.75px]">
                        -{" "}
                        {filterByTagType(
                            consultationData?.qp_cm_consultation_tags,
                            getTagId("complaints")
                        )?.map((el, i) => {
                            return <span key={i}>{el?.name},</span>;
                        })}
                    </div>
                </div>
            </div>

            <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                <div className="w-[20%] text-[#6D6D6D] flex justify-start items-center">
                    {t("allergies")}
                </div>
                <div className="flex-1 flex items-center">
                    -{" "}
                    {consultationData?.personal_details?.allergy?.map(
                        (el, i) => {
                            return <span key={i}>{el.name}, </span>;
                        }
                    )}
                </div>
            </div>

            <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                <div className="w-[20%] text-[#6D6D6D] flex justify-start items-center">
                    {t("advice_and_direction")}
                </div>
                <div className="flex-1 flex items-center">
                    <div className="lowercase mb-1 laptop:leading-[17.75px]">
                        - {consultationData?.qp_cm_advices?.description}
                    </div>
                </div>
            </div>

            <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                <div className="w-[20%] text-[#6D6D6D] flex justify-start items-center">
                    {t("test_and_investigation")}
                </div>
                <div className="flex-1 flex items-center">
                    <div className="lowercase mb-1 laptop:leading-[17.75px]">
                        -{" "}
                        {filterByTagType(
                            consultationData?.qp_cm_consultation_tags,
                            getTagId("TEST & INVESTIGATION")
                        )?.map((el, i) => {
                            return <span key={i}>{el?.name},</span>;
                        })}
                    </div>
                </div>
            </div>

            <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                <div className="w-[20%] text-[#6D6D6D] flex justify-start items-center">
                    {t("diagnosis")}
                </div>
                <div className="flex-1 ">
                    <div className="lowercase mb-1 laptop:leading-[17.75px]">
                        -{" "}
                        {filterByTagType(
                            consultationData?.qp_cm_consultation_tags,
                            getTagId("DIAGNOSIS")
                        )?.map((el, i) => {
                            return <span key={i}>{el?.name},</span>;
                        })}
                    </div>
                </div>
            </div>

            <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                <div className="w-[20%] text-[#6D6D6D] flex justify-start items-center">
                    {t("patient_history")}
                </div>
                <div className="flex-1 ">
                    -{" "}
                    {consultationData?.personal_details?.medical_history?.map(
                        (el, i) => {
                            return <span key={i}>{el.name}, </span>;
                        }
                    )}
                </div>
            </div>
            {consultationData?.qp_cm_vitals?.length > 0 && (
                <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                    <div className="w-[25%] text-[#6D6D6D] flex justify-start items-center">
                        {t("vitals")}
                    </div>
                    <div className="w-full h-full overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className="bg-[#EBF8EC]">
                                    {consultationData?.qp_cm_vitals.map(
                                        (el, i) => {
                                            return (
                                                <th
                                                    key={i}
                                                    className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal"
                                                >
                                                    {el?.type?.label}
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                <tr className="text-sm">
                                    {consultationData?.qp_cm_vitals.map(
                                        (el, i) => {
                                            return (
                                                <td
                                                    key={i}
                                                    className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal"
                                                >
                                                    {el?.value1}{" "}
                                                    {el?.type?.unit}
                                                </td>
                                            );
                                        }
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {consultationData?.medicine_data?.length > 0 && (
                <div className="border-b border-[#00000033] w-full flex py-4 gap-2">
                    <div className="w-[25%] text-[#6D6D6D] flex justify-start items-center">
                        {t("medicine_details")}
                    </div>
                    <div className="w-full h-full overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className="bg-[#EBF8EC]">
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        Medicine
                                    </th>
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        Unit
                                    </th>
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        Dosage
                                    </th>
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        When
                                    </th>
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        Frequency
                                    </th>
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        Duration
                                    </th>
                                    <th className="border border-[#00000033] p-2 text-[9.15px] laptop:text-[12.35px] text-[#6D6D6D] font-normal">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {consultationData?.medicine_data.map(
                                    (item, i) => {
                                        return (
                                            <tr className="text-sm" key={i}>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.medicineName}
                                                </td>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.unit.name}
                                                </td>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.dosage.name}
                                                </td>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.when.name}
                                                </td>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.frequency.name}
                                                </td>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.duration}
                                                </td>
                                                <td className="p-2 whitespace-nowrap border border-[#00000033] text-center leading-[14.2px] font-normal">
                                                    {item?.notes.name}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <div className=" flex my-2 gap-3">
                <span> {t("next_visit_date")} :-</span>
                <span>
                    {!consultationData?.next_visit_date ? (
                        ""
                    ) : (
                        <>
                            {moment(consultationData?.next_visit_date).format(
                                "MMMM DD, YYYY"
                            )}
                        </>
                    )}
                </span>
            </div>
            <button
                onClick={handlePrint}
                className="h-[40px] border rounded-md bg-[#C7EAC9] text-lg w-full mt-2"
            >
                Download PDF
            </button>
        </div>
    );
};

export default VisitPrescription;
